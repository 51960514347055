.modal {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.modal > svg {
  align-self: flex-end;
  cursor: pointer;
}

.crossIcon {
  align-self: flex-end;
  cursor: pointer;
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  right: 15px;
}

.main {
  padding: 2rem;
  background-color: var(--hexaWhite);
  display: flex;
  justify-content: center;
}

.buttonSubmit {
  display: flex;
  justify-content: space-between;
}

.main svg {
  cursor: pointer;
}

.buttonSubmit button:disabled {
  margin-right: 0;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem;
}

.error-input {
  width: 20rem;
}

.radio-group {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.radio {
  height: 4rem;
  padding: 0.5rem;
  border: 2px solid var(--inputGrey);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.radio:hover {
  border: 2px solid var(--primary);
  caret-color: var(--primary);
}

.radio-full {
  height: 4rem;
  border: 2px solid var(--inputGrey);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.input {
  margin: 0;
}

.input input {
  padding: 0;
  padding-left: 1rem;
}

.form-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.02em;
  color: black;
}

.labelDiv {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--labelColor);
  letter-spacing: 0.002em;
}

.labelDiv svg {
  padding-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 2rem 1rem;
  }
  .buttonSubmit {
    flex-direction: column;
    gap: 1rem;
  }

  .buttonSubmit button {
    width: 100%;
  }

  .row {
    flex-direction: column;
  }

  .error-input {
    width: 95%;
  }
}
@media only screen and (max-width: 500px) {
  .main {
    padding: 0 2rem 1rem 2rem;
  }
  .form {
    max-width: 100%;
  }
  .buttonSubmit {
    align-items: center;
  }
}
