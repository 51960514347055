.crossIcon {
  display: flex;
  padding-top: 1rem;
  padding-right: 3rem;
  justify-content: flex-end;
}
.crossIcon > svg {
  cursor: pointer;
}
.main {
  background: var(--hexWhite);
  margin: 0 1rem;
  /* font-Family: fonts.primary; */
  font-style: normal;
  padding: 0;
}

.popular {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.mostPopular {
  background: var(--hamptonsSecondary);
  border-radius: 6px 6px 0px 0px;
  color: var(--pureWhite);
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding: 0 1rem;
}

.packageList {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
}

.packageCommon {
  display: flex;
  width: 150px;
  flex-direction: column;
  min-height: 1rem;
  justify-content: space-around;
  align-items: center;
}

.packageHeading {
  background: var(--bg6);
  min-height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--labelColor);
}
.packageHeading .packageTitle {
  font-size: 20px;
}
.packageHeading .selected {
  background: var(--primary);
  min-height: 76px;
}
.packageHeading .selected span {
  color: var(--hexaWhite);
}
.packageTitle {
  width: 250px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  height: auto;
  padding: 0;
  align-items: center;
  color: var(--grey1);
}

.packageContent {
  min-height: 50px;
  width: 100%;
}
.packageContent .packageList {
  height: 2.2rem;
  box-shadow: inset 0px -1px 0px var(--grey4);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.packageContent .packageList .packageSelectedList {
  height: 2.2rem;
  background: var(--bg6);
  box-shadow: inset 0px -1px 0px var(--grey4);
}

.select {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 16px;
  margin-top: 20px;
  border: 2px solid #dfe3e8;
  box-sizing: border-box;
  border-radius: 4px;
}

.packageActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.packageHelp {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 24px;
}
.packageSupport {
  font-size: 18px;
  font-weight: 500;
  margin-left: 0.5rem;
  line-height: 24px;
  color: var(--primary);
}
.packageSupportEmail {
  font-size: 18px;
  font-weight: 600;
  margin-left: 3px;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}
.button {
  display: flex;
}
.button :nth-child(2) {
  margin-left: 1rem;
}
button {
  width: 160px;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}
.selectedPackage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedPackage :nth-child(1) {
  margin-right: 0.5rem;
}
.selectedText {
  color: var(--pureWhite) !important;
}
.selectedText svg {
  width: 14px;
  margin-right: 3px;
}

.packageName {
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin-top: 7px;
}
.disabled {
  pointer-events: none;
}
.packageLink {
  color: var(--primary);
  font-weight: bold;
  font-size: 14px;
  line-height: 125%;
}

.packageFooter {
  background: var(--grey4);
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.packageFooter .locationName {
  font-size: 14px;
  font-weight: bold;
  margin-left: 18px;
  width: 250px;
}

footer {
  margin-top: 10px;
  font: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey1);
}
.wrapper {
  margin: 1rem 2rem;
}
.mobileContainer {
  border-radius: 1rem;
  padding-bottom: 0.5rem;
  background-color: var(--hexWhite);
}
.mobilePackages {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  color: var(--hexWhite);
  border-radius: 1rem 1rem 0 0;
}
.mobilePackages > svg {
  height: 1rem;
}
.mobilePackages > svg > path {
  stroke: var(--hexWhite);
}
.mobileSelect {
  padding: 1rem 2rem;
  background-color: var(--hamptonsNewDisabled);
}
.mobileDescription {
  margin: 1rem 2rem;
}
.mobileDescriptionTitle {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 1rem;
}
.mobilePackageList {
  height: 2.2rem;
  box-shadow: inset 0px -1px 0px var(--grey4);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tickIcon {
  box-shadow: inset 0px -1px 0px var(--grey4);
  display: flex;
  align-items: center;
  width: 5rem;
  justify-content: center;
  height: 2.2rem;
  color: black;
  background: var(--bg6);
}
.mobileDescriptionText {
  color: var(--grey5);
  margin-bottom: 1rem;
}
.mobilePrice {
  margin: 0 2rem 0rem 2rem;
  border-bottom: 2px solid var(--grey4);
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.mobileSelectedPackage {
  padding: 2rem 1rem;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  background-color: var(--bg6);
  color: var(--black);
  margin: 0 2rem 1rem 2rem;
  border-radius: 0rem 0rem 1rem 1rem;
  align-items: center;
}
.mobileSelectedPackage button {
  width: 6rem;
  margin-right: 0;
}
.selectPackage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.selectedPackageName {
  font-weight: 400;
}
.viewDetails {
  margin-top: 1rem;
  color: var(--primary);
}
.viewDetails :active {
  font-weight: bold;
}
