.container {
  background: var(--backgroundColor);
  width: 100%;
  display: grid;
  grid-template-rows: 72px auto;
  height: 100%;
}
.mainContainer {
  display: flex;
  justify-content: center;
  background: var(--backgroundColor);
  height: 100%;
}

@media (max-width: 768px) {
  .header {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
