.container {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 0.3rem;
  background-color: var(--hexWhite);
}

.header {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
}

.header > h6 {
  color: var(--hexWhite);
  font-family: var(--fontFamily);
}

.logo {
  justify-self: center;
}

svg.cross {
  justify-self: end;
}

svg.cross path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.Wrap {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.row {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.ChooseFile {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button > button {
  width: 8rem;
}

.button > button:nth-child(1) {
  margin-right: 1rem;
}
