.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: space-between;
  align-items: end;
}

@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }
}
