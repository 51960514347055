.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
}

.container > svg {
  align-self: flex-end;
  position: fixed;
  cursor: pointer;
}
