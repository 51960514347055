.container {
  margin-bottom: 1rem;
}

.layout > *:not(:last-child) {
  margin-bottom: 1rem;
}

.container > *:not(:last-child) {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--bg2);
}
.upgrade {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.title {
  margin-top: 2rem;
}
.noSponsorsSubtitle {
  margin-top: 2rem;
}
.upgrade :nth-child(4) {
  cursor: pointer;
  margin-top: 2rem;
  height: auto;
  width: 25rem;
}
