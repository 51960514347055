.container {
  width: 100%;
  display: flex;
  padding: 0.8rem 0;
  text-align: center;
  background-color: var(--hexWhite);
  flex-wrap: wrap;
  min-height: 3.58rem;
  justify-content: center;
  overflow-x: hidden;
}
.wrap {
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.stepWrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.link {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey5);
}
.selected {
  color: var(--primaryRGB);
}

.selected > svg > path {
  stroke: var(--primary);
}
.selected > svg path[class="propertyIcon"] {
  stroke: var(--hamptonsSecondary);
}
.selected > svg path[class="homeIcon"] {
  stroke: var(--hamptonsSecondary);
}
.selected > svg path[class="planIcon"] {
  stroke: var(--hamptonsSecondary);
}

.link > span {
  padding: 0 1rem;
}
.steps {
  display: flex;
  justify-content: center;
  width: 80%;
}
.sponsoredBy {
  width: 20%;
}
.upgradeButton {
  margin-right: 2rem;
  min-width: 12rem;
}
.upgradeButton:hover {
  cursor: pointer;
}

.progressBar {
  border-bottom: solid 4px var(--secondary);
}
.progressBarSizeSmall {
  width: 35%;
}
.progressBarSizeMedium {
  min-width: 36rem;
  width: 45%;
}
.progressBarSizeLarge {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .progressBar {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    display: none;
    min-height: 6rem;
    justify-content: center;
    align-content: center;
    padding: unset;
  }
  .stepWrap {
    flex-direction: column;
    align-items: center;
  }
  .upgradeButton {
    margin-right: unset;
  }
  .link > span {
    display: none;
  }
  .step {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
  }
  .steps {
    min-width: 60vw;
    margin-bottom: 0.5rem;
    width: unset;
  }
  .sponsoredBy {
    width: unset;
    display: none;
  }
  .progressBar {
    display: none;
  }
}
