.container {
  display: grid;
  grid-template-columns: minmax(auto, 20rem) 1fr;
  column-gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  max-width: 1530px;
  background-color: var(--backgroundColor);
}
.button {
  display: flex;
  justify-content: space-between;
  grid-column: 2;
}
@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: unset;
  }
}
@media only screen and (max-width: 580px) {
  .container {
    display: unset;
    padding: 0rem;
  }
}
