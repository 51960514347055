.container {
  display: flex;
  flex-direction: column;
}

.container header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.container header span {
  display: flex;
  flex-direction: row;
}

.container header span > div {
  margin-right: 0.5rem;
}

.container main {
  padding-left: 1rem;
  color: var(--labelColor);
}

.container main > * {
  margin-bottom: 1rem;
}
.planning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--grey4);
}
.planningWrap {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  height: 2.2rem;
}
.planningPrice {
  background-color: var(--bg6);
  margin-right: 5rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
}
