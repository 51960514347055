.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
}

.container:hover {
  cursor: default;
}

.container p {
  margin: 0;
}

.container span {
  min-width: 2rem;
  min-height: 2rem;
  margin: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d4f2cc;
  color: var(--labelColor);
  height: fit-content;
  border-radius: 0.3rem;
}

.red {
  background-color: #fcd2ce !important;
}

.yellow {
  background-color: #fff0c0 !important;
}
