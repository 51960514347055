.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.container img {
  max-height: 2rem;
  width: auto;
}
