.leaflet-container {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  min-height: 280px;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 500;
}
