.container {
  margin-bottom: 1rem;
  border-radius: 6px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  text-align: left;
}

.titleFilters {
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  color: var(--labelColor);
}
.clearFiltersContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.clearFilters {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.clearFilters > svg {
  width: 0.8rem;
  margin-left: 0.5rem;
}
.clearFilters > svg > path {
  stroke: var(--labelColor);
}
.filters {
  background-color: var(--pureWhite);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.filter {
  max-width: 10rem;
}
.filtersMobile {
  background-color: var(--pureWhite);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: grid;
  column-gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
}
.titleFiltersMobile {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  color: var(--labelColor);
}
.clearFiltersMobile {
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.clearFiltersMobile > svg {
  width: 0.8rem;
  margin-left: 0.5rem;
}
.clearFiltersMobile > svg > path {
  stroke: var(--labelColor);
}
.icon {
  cursor: pointer;
  height: 100%;
  width: auto;
}

.icon path {
  stroke: var(--primary);
}
