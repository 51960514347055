.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header svg {
  height: 100%;
  width: auto;
}
.header > :last-child {
  cursor: pointer;
  height: 1.5rem;
  width: auto;
}

.header :nth-child(1),
.header :nth-child(2) {
  margin-right: 1rem;
}

.lists {
  margin-top: 1rem;
  color: var(--primary);
}

.lists > * {
  margin-top: 0.5rem;
}

.footerSection {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
