.container {
  background-color: var(--alertBox15percent);
  color: var(--grey);
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  align-items: center;
}

.content {
  color: var(--grey);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  text-align: center;
}
.content > button {
  margin-left: 0.5rem;
}

.image {
  min-width: 1.5rem;
  margin-right: 1rem;
}
.image path {
  stroke: var(--warningColor);
  stroke-width: 2px;
}

@media only screen and (max-width: 800px) {
  .container {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-bottom: 1rem;
  }
  .image {
    width: auto;
    height: 1.5rem;
    margin-right: 1rem;
  }
}
