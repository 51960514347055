.itemContainer {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 36rem;
}
.title {
  bottom: 1rem;
}
.button {
  display: flex;
  top: 1rem;
}
