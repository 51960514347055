.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list {
  height: 9rem;
  background: var(--hexWhite);
  padding: 12px 0 12px 16px;
  color: var(--labelColor);
  margin-top: 0;
  border: none;
  border-radius: 4;
  list-style-type: none;
  position: relative;
  text-transform: none;
  display: flex;
  flex-direction: column;
  height: 130;
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 1px rgb(9 30 66 / 31%), 0px 8px 16px rgb(9 30 66 / 25%);
}
@media (max-width: 460px) {
  .list {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.list:disabled {
  background: var(--cmyk);
}

.listItem {
  padding: 0;
  cursor: pointer;
  padding-left: 0.5rem;
  color: var(--black);
}

.listItem:hover {
  color: var(--hexWhite);
  background-color: var(--primary);
}

.selectedListItem {
  color: var(--hexWhite);
  background-color: var(--primary);
  font-weight: 700;
}

.errorText {
  color: var(--red);
  padding-top: 6px;
  width: 436px;
}

.downArrow {
  pointer-events: none;
  position: absolute;
  left: calc(100% - 10%);
  top: 46px;
}
.downArrow > path {
  stroke: var(--black);
}
