.container {
  display: flex;
  flex-direction: column;
}

.container > * {
  margin-top: 0.5rem;
}
.section {
  margin: 0.5rem 0 1rem 0;
}
