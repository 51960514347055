.container {
  width: 100%;
  display: inline-block;
  position: relative;
}
.container p {
  margin: 0;
}

.container > button {
  width: 100%;
  height: 2rem;
  color: var(--labelColor);
}

.container > button svg path {
  stroke: var(--black);
}

.container .menu {
  color: var(--labelColor);
  right: 0%;
  width: max-content;
  outline: none;
  z-index: 3;
  position: absolute;
  border-radius: 0.8rem;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  font-style: normal;
  font-weight: normal;
  background-color: var(--pureWhite);
}

.container .menu > * {
  padding: 1rem;
}

.view {
  color: var(--primary);
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem 0.8rem 0 0;
  transition: all 0.5s;
}

.view svg path {
  stroke: var(--primary);
}

.view:hover {
  color: var(--pureWhite);
  background-color: var(--primary);
}

.view:hover svg path {
  stroke: var(--pureWhite);
}

.view :first-child {
  margin-right: 0.5rem;
}

.delete {
  color: var(--error);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.8rem 0.8rem;
  transition: all 0.5s;
}
.delete :first-child {
  margin-right: 0.5rem;
}
.delete:hover {
  color: var(--pureWhite);
  background-color: var(--error);
}

.delete:hover svg path {
  stroke: var(--pureWhite);
}
