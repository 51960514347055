.notes {
  margin-top: 2rem;
}

.heading {
  color: var(--primary);
}

.alertBox {
  margin-bottom: 2rem;
}
.alertBox > div {
  padding: 1.5rem;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.alertBox > div > svg {
  margin-left: 0.5rem;
  min-width: 2rem;
  min-height: 2rem;
}
.alertBox > div > span {
  font-size: 1rem;
  text-align: center;
}

.noteText {
  color: var(--labelColor);
}

.title {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.titleDesc {
  margin-left: 0.4rem;
}

.crmUploads {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.crmUploadContainer {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: rgba(240, 240, 240, 0.5);
  max-width: 20%;
  min-width: fit-content;
  padding: 1rem;
  margin: 0.32rem;
}

.crmItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem;
  flex-wrap: nowrap;
}

.crmItemLeft {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  width: 10rem;
}

.crmItemRight {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  width: fit-content;
  justify-content: flex-end;
  padding-left: 2rem;
}
@media screen and (max-width: 1550px) {
  .crmUploadContainer {
    max-width: 48%;
    min-width: 48%;
  }
}
@media screen and (max-width: 1200px) {
  .crmUploadContainer {
    max-width: 100%;
    min-width: 100%;
  }
}
