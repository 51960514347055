.container > * {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.file {
  display: flex;
  flex-direction: row;
}
.file > svg {
  cursor: pointer;
  margin-left: 2rem;
}

.chooseFile {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
