.container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
}

.mainProviderContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  background-color: var(--inputGrey50percent);
}

.mainProviderContainer > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.providerContainer {
  background-color: var(--inputGrey50percent);
  padding: 1rem;
}

.secondaryProviders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.secondaryProviders > * {
  flex-grow: 1;
  max-width: 32%;
}

.providerContainer .image {
  position: relative;
  width: 100%;
}

.iframe {
  height: 600px;
  margin-bottom: 2rem;
}
