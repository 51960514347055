.container {
  padding: 1.2rem 3rem;
  width: 100%;
  color: var(--labelColor);
}

.containerBottom {
  color: var(--labelColor);
  width: 100%;
  padding: 0 3rem;
}

.hDivider {
  width: 100%;
  border: 1px solid #eee;
  height: 1px;
}

.disclaimer {
  text-transform: none;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.disclaimer > span {
  font-size: small;
}

.confirmButton {
  padding: 1rem;
  background-color: var(--primary);
  color: white;
  border-radius: 0.3rem;
  height: 3.2rem;
  width: 192px;
  display: flex;
  align-items: center;
  transition: width 0.3s;
  margin: 1rem;
  justify-content: flex-end;
}
.loading {
  width: 4rem;
  transition: width 1s;
}
.cPError {
  color: rgba(255, 0, 0, 0.6);
}

.contactButton {
  background: var(--primary);
  padding: 1rem 3rem;
  margin: 0.4rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  text-align: center;
  border: none;
}
.contactButton :hover {
  cursor: pointer;
}
.contactButton > a {
  text-decoration: none;
  color: #fff;
}

.topMenu {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: var(--hexWhite);
  padding: 1rem;
  border-radius: 5px;
  border-bottom: 3px solid;
  border-color: var(--grey4);
}
.topMenu :hover {
  text-decoration: underline;
}

.topMenu > span > a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.title {
  color: var(--primary);
  margin-bottom: 1rem;
}

.containerLeftRight {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  padding-top: 1.2rem;
  padding-bottom: 2rem;
}
.rentalAndSalesContainer {
  background-color: var(--hexWhite);
  padding: 2rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}

.rentalAndSalesContainer > * {
  margin-bottom: 1rem;
}

.rentalAndSalesContainer > span {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.left {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.map {
  z-index: 0;
  height: 100%;
}
.mapWarning {
  background-color: rgb(255, 184, 184);
  padding: 0rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  text-align: center;
}
.mapGuide {
  background-color: white;
  padding: 0.4rem;
  padding-left: 1rem;
  display: flex;
  justify-content: left;
  cursor: pointer;
  align-items: center;
  margin-bottom: 2rem;
}
.mapGuide > svg {
  margin-left: 0.5rem;
  max-width: 16px;
  fill: var(--labelColor);
}
.mapGuide:hover {
  text-decoration: underline;
}
.mapGuideButton {
  padding: 0.4rem;
  display: flex;
  justify-content: right;
  cursor: pointer;
  align-items: center;
  padding-top: 2.5rem;
}
.mapGuideButton > button {
  margin-bottom: 0.5rem;
  align-self: flex-end;
}
.right {
  width: 100%;
}
.formContainer {
  margin-bottom: 2rem;
}
.formTop {
  color: var(--primary);
  background-color: var(--hexWhite);
  padding: 2rem 3.5rem 2rem 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formTop > svg > path {
  stroke: var(--primary);
}
.formTop:hover {
  text-decoration: underline;
}
.journeyButton {
  display: flex;
  justify-content: flex-end;
}
.notClickable {
  pointer-events: none;
  cursor: not-allowed;
}
.continueButtonContainer {
  padding: 0.8rem 0rem;
  display: flex;
  justify-content: flex-end;
}

.tableContainer {
  background: white;
  padding: 0 2rem;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: unset;
  }
  .mapGuide {
    padding: 0rem 2rem;
  }
  .rentalAndSalesContainer {
    padding: 1rem;
  }
  .containerLeftRight {
    display: unset;
  }
  .continueButtonContainer {
    padding: 0.8rem 0rem;
    display: flex;
    justify-content: flex-end;
  }
}
