.container {
  color: var(--labelColor);
  height: 100%;
  max-height: 90vh;
  width: 80vw;
  max-width: 1000px;
}
.closeIcon {
  display: flex;
  justify-content: flex-end;
  background: var(--primary);
}
.closeIcon > svg {
  filter: invert(66%) sepia(68%) saturate(1721%) hue-rotate(20deg)
    brightness(150%) contrast(103%);
  margin: 1rem;
  cursor: pointer;
}

.title {
  padding: 1rem 0rem;
  background: var(--primary);
  color: white;
  text-align: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  padding: 1rem 0rem;
}
.formRow > label {
  width: 45%;
}

.horBar {
  height: 1px;
  border: 1px solid rgba(200, 200, 200, 0.4);
  width: 90%;
  margin-top: 1.5rem;
}

.subtitle {
  margin: 1rem 0rem;
  width: 90%;
  text-align: left;
}

.messageBox {
  width: 90%;
  height: max-content;
  font-family: "Basier Circle";
}

.bottomButtons {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: flex-end;
  margin: 2rem 0rem;
}

.bottomButtons > button {
  width: 8rem;
}
