.container {
  display: flex;
  flex-direction: column;
}

.container > * {
  margin-top: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  align-items: flex-end;
}
.NewSearch {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2rem;
}

.row > :nth-child(1) {
  margin-right: 1rem;
}
.noUPRN {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.noUPRN > button {
  max-width: unset;
  width: 100%;
}
@media (max-width: 768px) {
  .container {
    margin-top: 2rem;
  }
}
