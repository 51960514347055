.main {
  width: 100%;
  height: auto;
  display: grid;
  justify-items: center;
  padding: 0 0rem 1.875rem 0rem;
}
iframe[style*="2147483647"] {
  display: none;
}
.actionsRequired {
  width: 100%;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcf1cdb2;
  padding: 0 1rem;
}
.actionsRequiredCritical {
  width: 100%;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbe6e4;
  padding: 0 1rem;
}
.warningText {
  margin-left: 1rem;
}
.leftSide {
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.rightSide {
  display: flex;
  align-items: center;
}
.rightSide > button {
  color: var(--labelColor);
  border-color: var(--labelColor);
}
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0.312rem 1.5rem 0 0;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .actionsRequired {
    height: unset;
    flex-direction: column;
    padding: 0.5rem 1rem;
  }
  .actionsRequiredCritical {
    height: unset;
    flex-direction: column;
    padding: 0.5rem 1rem;
  }
  .leftSide {
    margin-bottom: 0.5rem;
  }

  .footer {
    display: none;
    padding-bottom: 5rem;
    margin: 1.875rem 0 0 1.5rem;
    justify-content: flex-start;
  }
}
