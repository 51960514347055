.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0rem 2.5rem 3rem 2.5rem;
  align-items: center;
  position: relative;
  max-width: 40rem;
  width: 100%;
  --magic-number: 0.09719;
  --skew-padding: calc(-100vw * var(--magic-number));
}

.container > *:not(.background) {
  z-index: 1;
}
.backgroundWave {
  position: absolute;
  background-size: 100% auto;
}

.container img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 3rem;
}

.background {
  position: absolute;
  z-index: 0;
  background-color: #f0fbfa;
  width: 100%;
  height: 100%;
  transform: skewY(-21deg) translateY(-81%);
}

.formContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0 1rem;
}

.sponsorForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 2rem;
  width: 100%;
}
.title {
  margin-top: 2rem;
}
.subtitle {
  color: var(--primary);
  text-align: center;
}

.noSponsorsSubtitle {
  color: var(--primary);
  text-align: center;
  margin-bottom: 3rem;
  max-width: 26rem;
}
.noSponsorsSubtitle svg {
  vertical-align: text-bottom;
  margin-left: 0.3rem;
}
.noSponsorsSubtitle svg:hover {
  cursor: pointer;
}
.logo {
  margin-top: 8rem;
  display: flex;
  row-gap: 0.8rem;
  flex-direction: column;
  align-items: center;
}

.sponsor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-top: 0.37rem solid var(--primaryRGB);
  background: var(--hexWhite);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.sponsor:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px var(--primaryRGB70), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sponsor.selected {
  box-shadow: 0px 4px 4px var(--primaryRGB70), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.premiumSponsor {
  padding: 1.8rem;
  border-top: 0.37rem solid var(--secondaryRGB);
}

.premiumSponsor:hover {
  box-shadow: 0px 4px 10px var(--secondaryRGB70),
    0px 4px 4px rgba(0, 0, 0, 0.25);
}

.premiumSponsor.selected {
  box-shadow: 0px 4px 4px var(--secondaryRGB70), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.secondaryProviders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.secondaryProviders > * {
  flex-grow: 1;
  max-width: 32%;
}
.suggestSponsorText {
  margin-bottom: 1rem;
  color: var(--grey5);
  text-align: center;
}
.container footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.noSponsorFooter {
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.noSponsorFooter > * {
  width: 100%;
}

.suggestSponsorButtonText {
  text-align: center;
}

.bottomText {
  text-align: center;
  line-height: 1rem;
}
.termsAndConditions {
  white-space: nowrap;
}
@media only screen and (max-width: 580px) {
  .container {
    padding: 0 1rem;
    row-gap: 0.5rem;
  }
  .logo {
    margin-top: 1rem;
  }

  .container footer {
    margin-top: unset;
    margin-bottom: 1rem;
  }
  .termsAndConditions {
    margin-bottom: 2rem;
  }
  .termsAndConditions > div > span {
    flex-wrap: wrap;
  }
}
