.label {
  max-width: min-content;
}

.select {
  padding: 0.3rem;
  margin: 0.2rem 0.2rem;
  border: none;
  color: var(--primary);
  max-width: 12rem;
  font-size: medium;
}
.selected {
  padding: 0.3rem;
  margin: 0.2rem 0.2rem;
  border: none;
  color: grey;
  max-width: 12rem;
  font-size: medium;
}
.select::after {
  border-color: red;
}

.option {
  border: none;
  color: grey;
}
select option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
  color: white;
}

.optionDisabled {
  display: none;
}
