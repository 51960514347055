.row {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.row > :nth-child(2) {
  margin-left: 1rem;
}
.jobDetailsrow {
  color: var(--labelColor);
  margin-bottom: 2rem;
}
.jobDetailsrow > textarea {
  width: 100%;
  height: 10rem;
}
.textarea::placeholder {
  font-family: Arial;
  font-weight: 400;
}

.checkboxRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  color: var(--labelColor);
}
