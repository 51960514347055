.container {
  display: flex;
  flex-direction: column;
}

.container:hover {
  cursor: default;
}

.container > span {
  display: flex;
  align-items: center;
}

.container > span svg {
  margin-left: 0.5rem;
}

.container .errorMessage {
  color: var(--errorRed);
  display: flex;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
}

.container .errorMessage strong {
  margin-left: 0.5rem;
}

.clickable .label {
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.002em;
}

.notClickable {
  color: var(--labelColor);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.002em;
}
.noSponsor {
  color: var(--labelColor);
  font-style: normal;
  font-weight: 600;
  line-height: 4rem;
  letter-spacing: 0.002em;
}
