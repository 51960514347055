.container {
  background: var(--pureWhite);
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    max-width: none;
    max-height: none;
    margin: 0 1rem;
  }
}

.container header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--primary);
}

.container section {
  margin-top: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.container footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
}

.close {
  cursor: pointer;
}
