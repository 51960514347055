.heading {
  color: var(--primary);
}

.noteText {
  color: var(--labelColor);
}
.requirementList {
  color: var(--labelColor);
}

.modalContainer > span {
  padding: 0.2rem;
}
