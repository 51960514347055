.container {
  padding: 0rem;
}
.expired {
  color: var(--red);
}
.expiresSoon {
  color: var(--warningColor);
}
.chartContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.chart {
  padding: 1rem;
  width: 48%;
  margin: 1%;
  min-width: 300px;
  max-width: 500px;
  border: solid;
  border-width: 1px;
  border-radius: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.borderRadius {
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bars + .bars {
  border-top: 0;
}
.bars.noborder_bottom {
  border-bottom: 0;
}
.bars .noborder_top {
  border-top: 0;
}
.bars {
  display: grid;
  position: relative;
  min-width: 300px;
}
.bars .bar {
  display: flex;
  margin-bottom: 2px;
}
.bars .bar .th {
  padding: 5px;
}
.bars .bar div {
}
.bars .bar .col_1 {
  width: 64%;
}
.bars .bar .col_2,
.bars .bar .col_3 {
  width: auto;
}
.bars .bar .block {
  margin-bottom: 0px;
  padding: 0 4px;
  min-height: 30px;
}
.bars .bar .block:last-child {
  margin-bottom: 0;
}
.bars .range {
  color: #000;
  position: relative;
  font-weight: 700;
  margin-left: 0.1rem;
}
.bars .title {
  color: #000;
  float: right;
  font-weight: 700;
  text-align: center;
  margin-right: 0.4rem;
}
.bars small {
  display: block;
  line-height: 1em;
  opacity: 0.5;
  padding: 8px 4px;
}
.bars .pointer.current_only {
  left: 80%;
}
.bars .pointer.current {
  left: 70%;
}
.bars .pointer.potential {
  left: 88%;
}
.bars .pointer {
  display: block;
  margin: 0;
  position: absolute;
}
.bars .pointer span {
  color: #000;
  font-weight: 700;
  padding: 2px 8px;
  position: relative;
}
.bars .pointer span:after {
  border: solid transparent;
  border-width: 18px;
  content: " ";
  height: 12;
  margin-top: -18px;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 50%;
  width: 0;
}
.bars .rating_a_plus span:after,
.bars .rating_a span:after {
  border-right-color: #008054;
}
.bars .rating_b span:after {
  border-right-color: #19b459;
}
.bars .rating_c span:after {
  border-right-color: #8dce46;
}
.bars .rating_d span:after {
  border-right-color: #ffd500;
}
.bars .rating_e span:after {
  border-right-color: #fcaa65;
}
.bars .rating_f span:after {
  border-right-color: #ef8023;
}
.bars .rating_g span:after {
  border-right-color: #e9153b;
}
.bars .impact_rating_a_plus span:after,
.bars .impact_rating_a span:after {
  border-right-color: #cbe4f3;
}
.bars .impact_rating_b span:after {
  border-right-color: #98bfe6;
}
.bars .impact_rating_c span:after {
  border-right-color: #72a2d6;
}
.bars .impact_rating_d span:after {
  border-right-color: #4d85c2;
}
.bars .impact_rating_e span:after {
  border-right-color: #a6a6a8;
}
.bars .impact_rating_f span:after {
  border-right-color: #939094;
}
.bars .impact_rating_g span:after {
  border-right-color: #827f82;
}
.rating_a {
  background: #008054;
  margin-right: 70%;
}
.rating_b {
  background: #19b459;
  margin-right: 60%;
}
.rating_c {
  background: #8dce46;
  margin-right: 50%;
}
.rating_d {
  background: #ffd500;
  margin-right: 40%;
}
.rating_e {
  background: #fcaa65;
  margin-right: 30%;
}
.rating_f {
  background: #ef8023;
  margin-right: 20%;
}
.rating_g {
  background: #e9153b;
  margin-right: 10%;
}
.impact_rating_a {
  background: #cbe4f3;
  margin-right: 70%;
}
.impact_rating_b {
  background: #98bfe6;
  margin-right: 60%;
}
.impact_rating_c {
  background: #72a2d6;
  margin-right: 50%;
}
.impact_rating_d {
  background: #4d85c2;
  margin-right: 40%;
}
.impact_rating_e {
  background: #a6a6a8;
  margin-right: 30%;
}
.impact_rating_f {
  background: #939094;
  margin-right: 20%;
}
.impact_rating_g {
  background: #827f82;
  margin-right: 10%;
}
.rating_a,
.rating_b,
.rating_c,
.rating_d,
.rating_e,
.rating_f,
.rating_g {
  color: #111;
}
.rating {
  font-weight: 400;
  line-height: 1em;
  margin-bottom: 1px;
  margin-bottom: 0.1rem;
  padding: 5px;
  padding: 0.5rem;
}
.rating input {
  margin: 0;
}
.rating label {
  cursor: pointer;
  display: block;
  padding-left: 4px;
  padding-left: 0.4rem;
  text-align: right;
  width: 100%;
}
.rating label span {
  display: inline-block;
  float: left;
}
.rating strong {
  color: #fff;
  -webkit-text-stroke: 1px #111;
}
.ratingsContainer {
  display: flex;
  flex-direction: row;
}
.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #dfe9f4;
  padding: 0.8rem;
  margin: 0.2rem;
  margin-bottom: 1rem;
  width: 50%;
  border-radius: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.ratingSvg {
  padding-right: 1rem;
}
.noEPCContainer {
  padding: 9px;
}
.noEPCBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 13pt;
  color: #637381;
  width: 100%;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.noEPCBox > h5 {
  padding-top: 10px;
}
@media only screen and (max-width: 1314px) {
  .chart {
    width: 100%;
  }
}
