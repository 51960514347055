.container {
  height: 100%;
  width: 100%;
  color: var(--labelColor);
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
@media only screen and (max-width: 580px) {
  .iframe {
    min-height: 25rem;
  }
}
