.container {
}

.title {
  color: var(--primary);
  margin-bottom: 1rem;
}

.hDivider {
  width: 100%;
  border: 1px solid #eee;
  height: 1px;
}

.disclaimer {
  text-transform: none;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.disclaimer > span {
  font-size: small;
}

.contactButton {
  background: var(--primaryRGB80);
  padding: 1rem 3rem;
  margin: 0.4rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  text-align: center;
  border: none;
}
.contactButton > a {
  text-decoration: none;
  color: #fff;
}
