.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0rem 2rem;
  align-items: center;
  position: relative;
  max-width: 37rem;
  width: 100%;
  --magic-number: 0.09719;
  --skew-padding: calc(-100vw * var(--magic-number));
}

.container > *:not(.background) {
  z-index: 999;
}
.backgroundWave {
  position: absolute;
}
.backgroundWave > svg > path {
  fill: var(--lightRed);
}

.networkMessage {
  height: 15rem;
  max-width: 20rem;
  display: flex;
  align-items: center;
}
.message {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message > h1 {
  margin-bottom: 2rem;
  color: var(--red);
}
