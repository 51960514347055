.container {
  display: flex;
  flex-direction: column;
}

.file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.4rem;
  margin-bottom: 0.4rem;
}
.file > svg {
  cursor: pointer;
  margin-left: 2rem;
}
