.container input {
  width: 100%;
  border: 2px solid var(--inputGrey);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
}

.container input:hover {
  border: 2px solid var(--activeInput);
}
.container input:focus-within {
  border: 2px solid var(--activeInput);
}

/* Disabled */
.readOnly input {
  width: 100%;
  border: 2px solid var(--inputGrey);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
  background-color: rgba(225, 225, 225, 0.5);
  color: hsl(0, 0%, 60%);
}

.readOnly input:hover {
  border: 2px solid var(--inputGrey);
  cursor: default;
}
.readOnly input:focus-within {
  border: 2px solid var(--inputGrey);
}

.default {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.container input[class="PhoneInputInput"] {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.dashboardFilter {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.inputError input {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.inputError input:hover {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.inputError input:focus-within {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
