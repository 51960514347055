.container {
  max-width: 40rem;
  padding: 2rem;
}
.CrossIcon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  cursor: pointer;
}
.title {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.subTitle {
  margin-bottom: 2rem;
}
.description {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.button {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.start {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
