.container {
  /* min-height: 25rem; */
  width: 100%;
  min-width: 13rem;
  height: auto;
  background-color: var(--hexaWhite);
  border-radius: 4;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
}

.contentDetails {
  height: auto;
}
.headingBread {
  height: 4rem;
  box-shadow: 1px 1px rgb(196 196 196 / 40%);
  display: flex;
  align-items: center;
}
.headingDiv {
  height: 4rem;
  box-shadow: 1px 1px rgb(196 196 196 / 40%);
  display: flex;
  align-items: center;
  color: var(--primary);
}

.icon {
  margin-left: 1.5rem;
  color: var(--Primary);
}

.text {
  margin-left: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2rem;
  color: var(--Primary);
}
