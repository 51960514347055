.container {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 3px;
  background-color: var(--hexWhite);
  width: 25rem;
  max-width: 100%;
}

.header {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
  color: var(--hexWhite);
}

.cross {
  cursor: pointer;
  justify-self: end;
  align-self: center;
}

.logo {
  align-self: center;
  justify-self: center;
}

svg.cross path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.content {
  padding: 1rem 2rem 2rem 2rem;
}
.mailLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.mainText {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}
.subText {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: var(--labelColor);
}
.button {
  display: flex;
  justify-content: center;
}
