.disclaimer {
  padding: 0 1rem;
  background-color: #f9fafb;
}

.notes {
  padding: 1rem 1rem;
  background-color: #f9fafb;
}

.divider {
  height: 1px;
  border: 1px solid #dfe3e8;
}

.markdownContainer img {
  max-width: 100%;
}
.markdownContainer {
  color: var(--labelColor);
}

.textImageBoxContainer {
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  padding: 1rem;
  justify-content: space-between;
}
.textImageBoxImage {
  width: 40%;
}
.textImageBoxImage > img {
  width: 100%;
  height: auto;
}
.textImageBoxText {
  width: 55%;
}
