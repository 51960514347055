.container {
  width: 100%;
}
.container > * {
  height: 100%;
  width: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
.upgrade {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.title {
  margin-top: 2rem;
}
.noSponsorsSubtitle {
  margin-top: 2rem;
}
.upgrade :nth-child(4) {
  cursor: pointer;
  margin-top: 2rem;
  height: auto;
  width: 25rem;
}
@media only screen and (max-width: 580px) {
  .container > * {
    height: unset;
  }
}
