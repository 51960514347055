/* Customize the label (the container) */
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
  height: 2rem;
  border: 2px solid var(--inputGrey);
  border-radius: 4px;
  padding: 1.5rem;
  width: 100%;
  /* margin-right: 0.5rem; */
  transition: all 0.25s linear;
}

.container:hover {
  border: 2px solid var(--activeInput);
}
.container.readOnly:hover {
  border: 2px solid var(--inputGrey);
  cursor: default;
}

.container .label {
  margin-left: 0.5rem;
  text-wrap: nowrap;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.readOnly {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
  height: 2rem;
  border: 2px solid var(--inputGrey);
  border-radius: 4px;
  padding: 1.5rem;
  width: 100%;
  /* margin-right: 0.5rem; */
  transition: all 0.25s linear;
  background-color: rgba(225, 225, 225, 0.5);
  color: hsl(0, 0%, 60%);
}

.readOnly:hover {
  border: 2px solid var(--activeInput);
}
.readOnly.readOnly:hover {
  border: 2px solid var(--inputGrey);
  cursor: default;
}

.readOnly .label {
  margin-left: 0.5rem;
  text-wrap: nowrap;
}

/* Hide the browser's default radio button */
.readOnly input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  display: grid;
  grid-template-columns: 1fr;
}

/* Style the indicator (dot/circle) */
.checkmark span.background {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--primary);
  grid-row-start: 1;
  grid-column-start: 1;
}

.checkmark span.unchecked {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--hexWhite);
  grid-row-start: 1;
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
}

.checkmark span.uncheckedReadOnly {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: rgba(235, 235, 235, 1);
  grid-row-start: 1;
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
}

.checkmark span.checked {
  justify-self: center;
  align-self: center;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  grid-row-start: 1;
  grid-column-start: 1;
}
@media only screen and (max-width: 480px) {
  .container {
    display: flex;
    padding: 1.5rem 0.5rem;
    width: 8rem;
  }
}
