.header {
  width: 100%;
  height: 72px;
  position: sticky;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: var(--hexaWhite);
}
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo > img {
  max-width: 8rem;
  height: 44px;
  width: auto;
}
.navbar {
  color: var(--labelColor);
  display: flex;
  margin-bottom: 1rem;
  height: 100%;
  align-items: center;
  padding-right: 1rem;
}
.links {
  display: flex;
  text-decoration: none;
  align-items: flex-start;
}
.links > img {
  padding: 0 0.2rem;
}

@media (max-width: 768px) {
  .header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .logo {
    padding: 14px;
  }
}
