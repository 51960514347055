.container {
  display: flex;
  flex-direction: column;
}

.container header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.container header span {
  display: flex;
  flex-direction: row;
}

.container header span > div {
  margin-right: 0.5rem;
}

.container main {
  color: var(--labelColor);
}

.container main > * {
  margin-bottom: 1rem;
}

.headerText {
  color: var(--labelColor);
}
