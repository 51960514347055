.spanClick {
  text-align: center;
  text-decoration-line: underline;
  color: var(--primary);
  text-decoration: none;
  font-weight: "600";
  cursor: pointer;
  display: flex;
  align-items: center;
}
.clickSupport {
  cursor: pointer;
  color: var(--primary);
  text-decoration: none;
}

.spanClick:focus {
  outline: none;
}

.atag {
  cursor: pointer;
}

.atag:focus {
  outline: none;
}

.aLink {
  cursor: pointer;
  color: var(--grey5);
  text-decoration: none;
}
.activeOverview {
  font-weight: 800;
  color: var(--primaryRGB);
}
.activeRents {
  font-weight: 800;
  color: var(--primaryRGB);
}
.termsAndConditions {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--primaryRGB);
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-weight: bold;
}

.disabled {
  color: gray;
}
.disabled:hover {
  cursor: not-allowed;
  font-weight: 400;
}
