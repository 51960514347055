.container {
  padding: 1rem;
  color: var(--labelColor);
  height: 100%;
  max-height: 90vh;
}
.closeIcon {
  display: flex;
  justify-content: flex-end;
}
.WrapSteps {
  padding-bottom: 1rem;
}
.steps {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.steps > p {
  max-width: 25rem;
}

.steps > img {
  max-width: 25rem;
}

@media (max-width: 768px) {
  .steps > p {
    max-width: 25rem;
  }
  .steps > img {
    max-width: 25rem;
    width: 100%;
  }
}
