.container {
  width: 100%;
  height: fit-content;
  background-color: var(--hexWhite);
  margin-bottom: 1rem;
}

.container > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--primary);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--bg2);
}

.container > header :first-child {
  margin-right: 1rem;
}

.homeIcon > path {
  stroke: var(--primary);
}

.container > header svg {
  height: 100%;
  width: auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: gray;
}
.selectedLink {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--primary);
}
.selected {
  display: flex;
  align-items: center;
  background-color: #f0fbfa;
  padding: 0.2rem 1rem;
  border-radius: 0.8rem;
}
.selected > svg {
  width: 16px;
  min-width: 16px;
}
.notSelected {
  display: flex;
  align-items: center;
  background-color: #f9fafb;
  padding: 0.2rem 1rem;
  border-radius: 0.8rem;
}
.notSelected > svg {
  width: 16px;
  min-width: 16px;
}
.circleLink {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
}
.circleLink > svg {
  min-width: 22px;
}

.checked > circle {
  fill: var(--primary);
}

.circleLink :nth-child(1) {
  margin-right: 0.5rem;
}
.circleLink :nth-child(2) {
  margin-right: 0.5rem;
}
.bcsteps {
}
.step {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #919eab;
  flex-direction: column;
}
.innerStep {
  margin: 0.5rem 0;
  display: flex;
  width: 300px;
  flex-direction: row;
  padding-left: 1rem;
}

.step svg circle {
  stroke: var(--primary);
}

.step .firstColumn {
  justify-self: center;
}

.navButton {
  display: none;
}

.unstuck {
  display: none;
}
.stuck {
  display: none;
}

.infoTip {
  display: none;
  position: relative;
  max-width: 15rem;
  transition: 1s;
}
.dropdown:hover .infoTip {
  display: block;
  position: absolute;
  color: black;
  margin-left: 2rem;
  margin-top: -3rem;
  background-color: var(--pureWhite);
  border: solid 2px var(--grey4);
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 15rem;
}
