.header {
  width: 100%;
  height: 72px;
  position: sticky;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: var(--hexaWhite);
  /* align-items: center; */
}
.burger {
  margin: 1.5rem;
}
.slidePaneInfo {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--primaryRGB10);
}
.slidePaneInfo > svg {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
}

.address {
  display: flex;
  justify-content: space-between;
  padding: 0;
  color: var(--labelColor);
}
.logo {
  display: flex;
  width: 100%;
  margin-right: 1rem;
  justify-content: space-between;
  align-items: center;
}
.logo > img {
  max-width: 8rem;
  height: 44px;
  width: auto;
}
.navbar {
  color: var(--labelColor);
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.mobileMenu {
  width: 70%;
  display: block;
  border-top-right-radius: 3rem 3rem;
  border-bottom-right-radius: 3rem 3rem;
  margin-top: 2rem;
  padding-top: 3rem;
  background-color: var(--hexaWhite);
  box-shadow: 1px 1px var(--labelColor);
  transform: translateX(100%);
}

.closeIcon {
  display: flex;
  padding: 0.5rem;
}
.tabviewTitle {
  color: var(--labelColor);
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.tabview {
  color: var(--labelColor);
  display: flex;
  align-items: center;
  margin: 0rem 0 0.5rem 0.5rem;
}
.tabview > a {
  color: var(--labelColor);
  display: flex;
  text-decoration: none;
  align-items: center;
}
.tabviewActive > a > svg > path {
  stroke: var(--primary);
}
.tabviewActive > a > svg path[class="propertyIcon"] {
  stroke: var(--hamptonsSecondary);
}
.tabviewActive > a > svg path[class="homeIcon"] {
  stroke: var(--hamptonsSecondary);
}
.tabviewActive > a > svg path[class="planIcon"] {
  stroke: var(--hamptonsSecondary);
}
.tabviewDetails {
  border-radius: 0.8rem;
  color: var(--primary);
  padding: 0.4rem 1rem;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  margin: 0.5rem;
}
.tabviewDetails :hover {
  cursor: pointer;
}

.arrowActive > svg > path {
  stroke: var(--hamptonsSecondary);
  fill: var(--hamptonsSecondary);
}
.propertyReportPathActive {
  margin: 0.2rem 0 0.5rem 0;
}
.propertyReportPathActive > a {
  text-decoration: none;
  border-radius: 0.8rem;
  color: var(--primary);
  padding: 0.4rem 1rem;
  background-color: #f0fbfa;
  display: flex;
  align-items: center;
}
.propertyReportPathActive > a > svg {
  margin-right: 0.5rem;
  width: 16px;
  max-width: 16px;
}
.propertyReportPathActive > a > svg > circle {
  fill: var(--primary);
}
.propertyReportPath {
  margin: 0.2rem 0 0.5rem 0;
}
.propertyReportPath > a {
  text-decoration: none;
  border-radius: 0.8rem;
  color: var(--primary);
  padding: 0.4rem 1rem;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
}
.propertyReportPath > a > svg {
  margin-right: 0.5rem;
  width: 16px;
  max-width: 16px;
}
.breadcrumbMobile {
  color: var(--labelColor);
  display: flex;
}
.powered {
  margin-right: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--hamptonsSecondary);
  text-decoration: none;
}
.viewDetails {
  display: flex;
}
@media (max-width: 768px) {
  .header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .image-logo {
    width: 90px;
    height: 36px;
  }
  .logo {
    padding: 14px;
  }
}
