.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--listBackground);
  padding: 0.5rem;
  width: 100%;
  margin: 1rem 0rem 1rem 0rem;
}
.wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.5rem;
}

.wrap:last-child {
  margin-bottom: 0;
}

.documentName {
  display: flex;
  align-items: center;
}
.documentName svg {
  margin-right: 1rem;
}
.documentData {
  display: flex;
  align-items: center;
}
.documentData > :nth-child(1),
.documentData > :nth-child(2) {
  margin-right: 1rem;
  display: flex;
}
.documentData > :nth-child(3) {
  margin-right: 1rem;
}
.toolTipTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.2rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
.toolTip {
  display: flex;
}
.toolTip > svg {
  cursor: pointer;
}

.toolTip .toolTipText {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 6rem;
  height: 1.8rem;
  margin-top: 1.5rem;
  margin-left: -3.5rem;
}
.toolTip:hover .toolTipText {
  visibility: visible;
}
.editButton {
  cursor: pointer;
}
