.container {
  background-color: var(--lightBlue);
  position: relative;
  padding: 2rem;
  color: var(--labelColor);
}
.container > svg {
  position: absolute;
  right: 0;
  z-index: -1;
  top: 0;
}
.listContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.priceLogoWrap {
  display: flex;
  right: 0;
  position: absolute;
  max-width: 14rem;
  top: 0;
}
.priceLogoWrap :nth-child(1) {
  display: flex;
  margin: 2rem;
}

.header {
  color: var(--primary);
}

.header :nth-child(1) {
  margin-bottom: 1rem;
}
.header :nth-child(2) {
  margin-bottom: 1rem;
  color: var(--labelColor);
}
.list {
  margin: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
}
.list > span {
  margin-bottom: 0.5rem;
}
.button {
  cursor: pointer;
}
.wrapLogos {
  padding: 2rem 2rem 1rem 2rem;
}
.logos {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.logos > img {
  max-height: 5rem;
  height: auto;
  max-width: 10rem;
  margin-right: 2rem;
}
.logos > svg {
  max-height: 5rem;
  height: auto;
  max-width: 10rem;
  margin-right: 2rem;
}
