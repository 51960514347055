.container {
  width: 100%;
  background-color: var(--pureWhite);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  color: var(--labelColor);
}

.container tr {
  outline: solid 1px var(--grey4);
}

.container td,
th {
  vertical-align: middle;
  padding: 0.5rem;
}

.container thead > tr > * {
  padding-top: 1rem;
}

.container thead > tr > td:first-child {
  padding-left: 1rem;
}
.container thead > tr > td:last-child {
  padding-right: 1rem;
}

.container tbody > tr > td:first-child {
  padding-left: 1rem;
}

.container tbody > tr td:last-child {
  padding-right: 1rem;
}

.noResultCell {
  display: table-cell;
}

.noResult {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
text-align: left;} 

.noResult > svg {
width: 5rem;
height: 5rem
}