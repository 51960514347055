.container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.header {
  display: flex;
  flex-direction: column;
}
