.container {
  display: flex;
  background: var(--pureWhite);
  padding: 1rem;
  margin: 0.5rem;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.text {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  font-weight: 600;
}

.header {
  color: var(--grey2);
  font-size: 0.9rem;
  word-wrap: break-all;
  line-height: 1rem;
  letter-spacing: 0.002em;
}

.counter {
  font-size: 2rem;
}
