.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: var(--hexWhite);
  padding: 1rem;
  color: var(--labelColor);
}

.container > *:not(:last-child) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.container > header {
  color: var(--primary);
  border-bottom: 2px solid var(--bg2);
}
.h6PropertyBox {
  display: flex;
  justify-content: space-between;
}

.button {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0.7rem;
  }
  .UsefulInfoAndDownloads {
    display: none;
  }
}
