@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;515;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;500;515;600;700&display=swap");
.body-class {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
  background: #ededed;
  min-height: 100vh;
  margin: 0;
  color: #333333;
}

.container {
  width: 100%;
  max-width: 842px;
  margin: 0 auto;
  height: auto;
  background: #ededed;
  padding: 24px;
  box-sizing: border-box;
}

.content-row {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}

.left-col {
  max-width: 320px;
  width: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
}
.details {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  min-height: 20px;
  line-height: 24px;
  margin-bottom: 23px;
}
.address-col {
  background: #1b294a;
  padding: 16px 18px;
}

.address-box {
  display: flex;
  margin-bottom: 16px;
}

.address-box:last-child {
  margin-bottom: 0;
}

.address-text {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  font-weight: 600;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
}

.full-head {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ededed;
}

.head-icon {
  line-height: 0;
  margin-right: 17px;
}
.license-icon {
  line-height: 0;
  margin-top: 2px;
  margin-right: 8px;
}
.head-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #1b294a;
  font-weight: 600;
}
.logo-image {
  max-width: 122px;
  max-height: 40px;
}
.logo-image-white {
  padding: 10px 16px 10px 16px;
  border-radius: 20px;
  background: #f4d100;
}

.mandatory-obj-col {
  padding: 8px 16px;
}

.mandatory-obj-options {
  border-bottom: 1px solid #ededed;
  display: flex;
  padding: 9.5px 0px;
}

.mandatory-obj-options:last-child {
  border-bottom: none;
}

.object {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  flex-basis: 55%;
  max-width: 55%;
}

.length {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  font-weight: 600;
  flex-basis: 45%;
  max-width: 45%;
}

.right-col {
  max-width: 458px;
  height: auto;
  width: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
}

.proposal-col {
  padding: 4px 16px;
}

.heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 280px;
  letter-spacing: 0.002em;
}
.heading-empty {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.002em;
}

.price {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  font-weight: 600;
  margin-right: 5px;
}
.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
  margin-top: 8px;
}

.proposal-options {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ededed;
  padding: 13.5px 0;
}

.proposal-options:last-child {
  border-bottom: none;
}

.proposal-col-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.proposal-col-head-certificate {
  display: flex;
  flex-direction: row;
  color: #1b294a;
  width: 100%;
  height: auto;
  min-height: 24px;
  margin-left: 12px;
}
.proposal-col-head-certificate.price-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-estimate-box {
  padding: 8px 16px;
  background: #333333;
}

.estimate-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 7.5px 0;
}

.estimate-heading,
.estimate-price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #ffffff;
}

.total-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0;
}

.total {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #ffffff;
  font-weight: 600;
}

.total-price {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
}

.powered-col {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.powered-col img {
  margin-left: 9px;
}

@media (max-width: 991px) {
  .content-row {
    justify-content: space-between;
  }

  .left-col {
    max-width: 320px;
  }

  .right-col {
    max-width: 458px;
  }
}
