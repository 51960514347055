.container {
  cursor: pointer;
  position: relative;
  display: flex;
}
.container:hover > .tooltip {
  visibility: visible;
}

.container.disabled {
  cursor: unset;
}

.container.disabled > svg path {
  stroke: grey;
  fill: rgb(228, 228, 228);
}

.tooltip {
  top: 28px;
  left: -67px;
  color: var(--hexWhite);
  width: 10rem;
  height: 2.1rem;
  display: flex;
  padding: 5px 0px;
  z-index: 1;
  position: absolute;
  box-shadow: rgb(0 0 0 / 9%) 0px 1px 4px;
  min-height: 30px;
  text-align: center;
  visibility: hidden;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--primary);
}
