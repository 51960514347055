.container {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 1rem;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.1rem;
  width: 1.1rem;
  background-color: var(--hexWhite);
  border: 1px solid var(--hamptonsNewDisabled);
  border-radius: 3px;
}
.checkmark::after {
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark {
  background-color: var(--hexWhite);
  border: 1px solid var(--primary);
}

.container input:checked ~ .checkmark::after {
  content: "";
  display: block;
}
.container .checkmark::after {
  left: 0.3rem;
  top: 0;
  width: 0.2rem;
  height: 0.8rem;
  border: 1px solid var(--primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
@media only screen and (max-width: 768px) {
  .checkmark {
    height: 2rem;
    width: 2rem;
  }
  .container .checkmark::after {
    left: 0.5rem;
    width: 0.7rem;
    height: 1.6rem;
  }
  .container input {
    position: unset;
  }
}
