.container {
  display: flex;
  align-items: center;
}

.container > :nth-child(2) {
  margin: 0 0.5rem;
  color: var(--grey5);
}

.container span {
  width: 100%;
  height: 1px;
  background-color: var(--grey5);
}
