.h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24pt;
  line-height: 32pt;
  margin: 0;
}

.h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20pt;
  line-height: 24pt;
  margin-bottom: 6pt;
}

.h3 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.002em;
}

.h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
}

.PropertyBox {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.Dashboard {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  color: var(--primary);
}
.Documents {
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 2rem;
  margin-left: 0.8rem;
  color: var(--placeholderColor);
}
.manualInsert {
  font-size: 1.2rem;
}
.manualInsertResult {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}
.License {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
  /* margin-bottom: 1rem; */
}
.Compliant {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  color: var(--primary);
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.002em;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  min-height: 1.4rem;
  height: auto;
}

.link {
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--primary);
}

.list {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0.6rem;
}
.MapMarker {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 600;
  font-style: normal;
}
.Button {
  font-size: 1.05rem;
  font-weight: 400;
  font-style: normal;
}

.pTag {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.7rem;
}
.UploadDocuments {
  font-style: normal;
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 3rem;
}
.header {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
}
.licenseName {
  font-size: 1rem;
}
.licensePrice {
  font-size: 1rem;
  font-weight: bold;
}
.licenseDescription {
  font-size: 0.9rem;
}
.documentLabel {
  color: var(--black);
  font-size: 0.8rem;
  line-height: 1rem;
}
.documentLabelRef {
  font-weight: bold;
}
.documentRef {
  font-weight: bold;
  font-size: 0.8rem;
}
.documentName {
  font-weight: bold;
  font-size: 0.8rem;
}
.documentEditBtn {
  color: var(--primary);
  font-size: 0.9rem;
  font-weight: 600;
}
.headingText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.upgradeBanner {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
}
.upgradeBannerSub {
  font-size: 1rem;
  font-weight: bold;
}
.upgradeBannerList {
  font-size: 1rem;
  line-height: 1rem;
}
.jobDetailsMainText {
  font-style: normal;
  font-weight: Bold;
  font-size: 1.2rem;
}
.jobDetailsSubText {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
}
.termsAndConditions {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  display: flex;
}

.disclaimer {
  margin-top: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2rem;
  display: flex;
  color: #505050;
}

.tabText {
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  mix-blend-mode: normal;
}

.noteTitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
}

.noteText {
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.noteText {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.alertBox {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.002em;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  min-height: 1.4rem;
  height: auto;
}
.propertyInfoAnalytics {
  font-size: 0.9rem;
  font-weight: bold;
}
.propertyInfoAnalyticsData {
  font-size: 0.9rem;
}
.pickSponsor {
  font-size: 0.7rem;
  line-height: 1.5rem;
}
.pickSponsorBold {
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.5rem;
}
.notes {
  margin-top: 1rem;
}
