@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;515;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;500;515;600;700&display=swap);
/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/* User interaction
 * ========================================================================== */

:root {
  /* Whitelabel SVG Shades and Tints */
  --primary-color: #4eaea5;
  --primary-S10: #469d95;
  --primary-S20: #3e8b84;
  --primary-S30: #377a73;
  --primary-S40: #2f6863;
  --primary-S50: #275753;
  --primary-S60: #1f4642;
  --primary-S70: #173432;
  --primary-S80: #102321;
  --primary-S90: #081110;
  --primary-T10: #60b6ae;
  --primary-T20: #71beb7;
  --primary-T30: #83c6c0;
  --primary-T40: #95cec9;
  --primary-T50: #a7d7d2;
  --primary-T60: #b8dfdb;
  --primary-T70: #cae7e4;
  --primary-T80: #dcefed;
  --primary-T90: #edf7f6;
  /* Whitelabel SVG Shades and Tints */
  --alertBox: #f4d100;
  --alertBox15percent: rgba(244, 209, 0, 0.15);
  --primary: #4f9e96;
  --primaryRGB: rgb(79, 158, 150);
  --primaryRGB5percent: rgba(79, 158, 150, 0.5);
  --primaryRGB1percent: rgba(79, 158, 150, 0.1);
  --secondary: #f2c94c;
  --black: #1f1f1f;
  --cmyk: #d8d8d8;
  --red: #d50911;
  --error: #ed5e5e;
  --textLink: #1a0dab;
  --textRed: rgba(226, 87, 76, 1);
  --orangeColor: #fcebdb;
  --lightBlue: #e4f3f2;
  --lightRed: #fbe6e4;
  --yellowNew: rgba(255, 234, 138, 0.3);
  --yellowHex: #fff8da;
  --white: 255, 255, 255;
  --hexWhite: #ffffff;
  --skyBlue: #caedea;
  --backgroundColor: #f9fafb;
  --backgroundColorSecondary: rgb(249, 250, 251);
  --backgroundTransparent40percent: rgba(0, 0, 0, 0.4);
  --backgroundTransparent5percent: rgba(0, 0, 0, 0.05);
  --accent: #4eaea5;
  --accent2: rgba(78, 174, 164, 0.2);
  --lightAccent: #def0ef;
  --azure: #ecf3f3;
  --green: #38da33;
  --paleSkyRBG: rgba(106, 114, 112, 0.5);
  --grapeRGB: rgba(65, 64, 64, 0.2);
  --bg1: #fff6ef;
  --solidBg1: #fba364;
  --bg2: #f2f5fe;
  --bg3: #fefae5;
  --bg5: #ececec;
  --bg4: rgba(244, 209, 0, 0.1);
  --bg6: #f0fbfa;
  --bg7: rgba(122, 157, 246, 0.1);
  --yunoActive: rgba(244, 209, 0, 0.2);
  --label2: #7a9df6;
  --inputGrey: #dfe3e8;
  --inputGrey50percent: rgba(223, 227, 232, 0.5);
  --warningBox: rgba(244, 209, 0, 0.15);
  --warningColor: #f2c94c;
  --listBackground: #f9fafb;
  --errorRed: #e2574c;
  --backgroundError: rgba(226, 87, 76, 0.1);
  --activeInput: #4eaea5;
  --labelColor: #637381;
  --leftImageColor: #ebf4f4;
  --placeholderColor: #919eab;
  --accountLabel: #939eaa;
  --pureWhite: #ffffff;
  --warningDocumentColor: #dbb131;
  --workInProgressDocumentColor: #f49342;
  --borderColor: #ebeeef;
  --unComplaintColor: #e4574a;
  --uncompliantStatus: rgb(226, 87, 76);
  --uncompliantStatusBg: rgba(226, 87, 76, 0.15);
  --wipStatus: rgb(244, 147, 66);
  --wipStatusBg: rgba(244, 147, 66, 0.193);
  --compliantStatus: rgb(43, 97, 92);
  --compliantStatusBg: rgba(78, 174, 164, 0.172);
  --actionRequiredStatus: rgb(83, 106, 165);
  --actionRequiredStatusBg: rgba(122, 157, 246, 0.172);
  --subTitleColor: #313332;
  --darkGreyToolTip: #212b36;
  --grey1: #6a7270;
  --grayPrimary: #dddddd;
  --grey: #414040;
  --lightGrey: #ededed;
  --midGrey: #c4c4c4;
  --darkGrey: #767676;
  --grey2: #a7a7a7;
  --grey4: #e9ecec;
  --grey5: #9e9e9e;
  --hamptonsPrimary: #4f9e96;
  --hamptonsPrimaryAlpha: rgba(78, 174, 165, 0.05);
  --hamptonsNewPrimary: #4eaea5;
  --hamptonsSecondary: #f2c94c;
  --hamptonsFocus: #86bbd8;
  --hamptonsGrey: #c4c4c4;
  --hamptonsNewDisabled: #c4cdd5;
  --tablet: "@media (max-width:780px)";
  --mobile: "@media (max-width: 480px)";
  --fontFamily: "Basier Circle";
}

@media (max-width: 768px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 87.5%;
  }
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  outline: none;
  margin: 0;
}
input {
  outline: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  outline: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: var(--fontFamily);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
  cursor: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body > #root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

/* RESET TABLE STYLES */
/* RESET STYLES */
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* END RESET TABLE STYLES */
.multi-select {
  margin-right: 10px;
}

.multi-select .dropdown {
  width: 300px;
  z-index: 3;
}

.multi-select .dropdown .dropdown-heading {
  border: 2px solid #b8c2cb !important;
  padding: 4px 0 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 500;
}

.AlertBox_container__2hUMF {
  background-color: var(--alertBox15percent);
  color: var(--grey);
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  align-items: center;
}

.AlertBox_content__1o87E {
  color: var(--grey);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  text-align: center;
}
.AlertBox_content__1o87E > button {
  margin-left: 0.5rem;
}

.AlertBox_image__3RGc9 {
  min-width: 1.5rem;
  margin-right: 1rem;
}
.AlertBox_image__3RGc9 path {
  stroke: var(--warningColor);
  stroke-width: 2px;
}

@media only screen and (max-width: 800px) {
  .AlertBox_container__2hUMF {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-bottom: 1rem;
  }
  .AlertBox_image__3RGc9 {
    width: auto;
    height: 1.5rem;
    margin-right: 1rem;
  }
}

.Card_container__1Aa_s {
  display: flex;
  background: var(--pureWhite);
  padding: 1rem;
  margin: 0.5rem;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.Card_text__ThQ89 {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  font-weight: 600;
}

.Card_header__2iPUy {
  color: var(--grey2);
  font-size: 0.9rem;
  word-wrap: break-all;
  line-height: 1rem;
  letter-spacing: 0.002em;
}

.Card_counter__3hIzU {
  font-size: 2rem;
}

.PropertyBox_container__2aVIg {
  /* min-height: 25rem; */
  width: 100%;
  min-width: 13rem;
  height: auto;
  background-color: var(--hexaWhite);
  border-radius: 4;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
}

.PropertyBox_contentDetails__3nbfK {
  height: auto;
}
.PropertyBox_headingBread__1AECw {
  height: 4rem;
  box-shadow: 1px 1px rgb(196 196 196 / 40%);
  display: flex;
  align-items: center;
}
.PropertyBox_headingDiv__3o2a1 {
  height: 4rem;
  box-shadow: 1px 1px rgb(196 196 196 / 40%);
  display: flex;
  align-items: center;
  color: var(--primary);
}

.PropertyBox_icon__3E-S9 {
  margin-left: 1.5rem;
  color: var(--Primary);
}

.PropertyBox_text__1tjDs {
  margin-left: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2rem;
  color: var(--Primary);
}

.Typography_h1__3jtcU {
  font-style: normal;
  font-weight: 600;
  font-size: 24pt;
  line-height: 32pt;
  margin: 0;
}

.Typography_h2__3a6x6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20pt;
  line-height: 24pt;
  margin-bottom: 6pt;
}

.Typography_h3__28Yj1 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.002em;
}

.Typography_h6__2Q8wZ {
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
}

.Typography_PropertyBox__3Dw2s {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.Typography_Dashboard__1y7Dr {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  color: var(--primary);
}
.Typography_Documents__cocYd {
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 2rem;
  margin-left: 0.8rem;
  color: var(--placeholderColor);
}
.Typography_manualInsert__I5WDm {
  font-size: 1.2rem;
}
.Typography_manualInsertResult__7_ywc {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}
.Typography_License__7JRc9 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
  /* margin-bottom: 1rem; */
}
.Typography_Compliant__INrC_ {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  color: var(--primary);
}
.Typography_subtitle__iuNYr {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.002em;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  min-height: 1.4rem;
  height: auto;
}

.Typography_link__1TIzt {
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--primary);
}

.Typography_list__16nb4 {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0.6rem;
}
.Typography_MapMarker__vp8Oi {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 600;
  font-style: normal;
}
.Typography_Button__rz2O9 {
  font-size: 1.05rem;
  font-weight: 400;
  font-style: normal;
}

.Typography_pTag__q1R_4 {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.7rem;
}
.Typography_UploadDocuments__1Ayx1 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 3rem;
}
.Typography_header__1zoVU {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
}
.Typography_licenseName__3c9IV {
  font-size: 1rem;
}
.Typography_licensePrice__3RcJ8 {
  font-size: 1rem;
  font-weight: bold;
}
.Typography_licenseDescription__v8Qmt {
  font-size: 0.9rem;
}
.Typography_documentLabel__2_AQ9 {
  color: var(--black);
  font-size: 0.8rem;
  line-height: 1rem;
}
.Typography_documentLabelRef__26Y5Z {
  font-weight: bold;
}
.Typography_documentRef__19FVx {
  font-weight: bold;
  font-size: 0.8rem;
}
.Typography_documentName__32Fhp {
  font-weight: bold;
  font-size: 0.8rem;
}
.Typography_documentEditBtn__3A6pl {
  color: var(--primary);
  font-size: 0.9rem;
  font-weight: 600;
}
.Typography_headingText__1qdje {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.Typography_upgradeBanner__yDFYh {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
}
.Typography_upgradeBannerSub__p2BNT {
  font-size: 1rem;
  font-weight: bold;
}
.Typography_upgradeBannerList__1_m2I {
  font-size: 1rem;
  line-height: 1rem;
}
.Typography_jobDetailsMainText__3DR0e {
  font-style: normal;
  font-weight: Bold;
  font-size: 1.2rem;
}
.Typography_jobDetailsSubText__19SVQ {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
}
.Typography_termsAndConditions__2OgQK {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  display: flex;
}

.Typography_disclaimer__2nMuC {
  margin-top: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2rem;
  display: flex;
  color: #505050;
}

.Typography_tabText__2aRlI {
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  mix-blend-mode: normal;
}

.Typography_noteTitle__1ONd0 {
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
}

.Typography_noteText__1OiDM {
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.Typography_noteText__1OiDM {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.Typography_alertBox__ypM4x {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.002em;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  min-height: 1.4rem;
  height: auto;
}
.Typography_propertyInfoAnalytics__3ghc_ {
  font-size: 0.9rem;
  font-weight: bold;
}
.Typography_propertyInfoAnalyticsData__24LcT {
  font-size: 0.9rem;
}
.Typography_pickSponsor__1q9Jb {
  font-size: 0.7rem;
  line-height: 1.5rem;
}
.Typography_pickSponsorBold__1ikl3 {
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.5rem;
}
.Typography_notes__3H1yC {
  margin-top: 1rem;
}

.Button_container__3-PJN {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  background: none;
  font-weight: 600;
  font-size: 1rem;
  font-style: normal;
  text-align: center;
  font-family: "Basier Circle";
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Button_textColour__Q2T1P {
  color: var(--primary);
}

.Button_loader__35Lbo {
  position: relative;
  width: 0px;
  height: 0px;
  top: -15px;
  left: 17px;
}

.Button_noIcon__2aszk {
  justify-content: center;
}

.Button_large__3cA5m {
  min-height: 3.2rem;
}

.Button_small__3J-w0 {
  min-height: 2rem;
}

.Button_primary__3-MfP {
  background-color: var(--primary);
  color: var(--pureWhite);
  max-width: 18rem;
  width: unset;
}

.Button_secondary__1-9v0 {
  background-color: var(--pureWhite);
  color: var(--primary);
  border: 2px solid var(--primary);
}

.Button_fullWidth__1BEtK {
  background-color: var(--primary);
  color: var(--pureWhite);
  width: 100%;
  margin-right: unset;
}

.Button_destructive__3X-hl {
  background-color: var(--errorRed);
  color: var(--pureWhite);
}
.Button_greyed__d_6HL {
  background-color: var(--hamptonsNewDisabled);
  color: var(--pureWhite);
}

.Button_outlines__2ep_i {
  background-color: var(--pureWhite);
  border: 2px solid rgba(196, 196, 196, 0.4);
}

.Button_discreet__2C9hB {
  background-color: var(--primaryRGB20);
  color: var(--primary);
}
.Button_discreet__2C9hB > span {
  display: flex;
  justify-content: center;
}

.Button_noBorder__2mY-q {
  border: none;
}

.Button_padded__1nYvW {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: var(--primary);
  color: var(--pureWhite);
  max-width: 18rem;
  width: 18rem;
}

button.Button_container__3-PJN:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.Button_verifyAddress__2o7kF {
  margin-left: 2rem;
  border: 2px solid var(--black);
  color: var(--black);
  font-weight: 700 !important;
  height: 1rem;
}
.Button_bannerButton__2w4l8 {
  margin-left: 2rem;
  border: 2px solid var(--black);
  color: var(--black);
  font-weight: 700 !important;
  height: 1rem;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Button_verifyAddress__2o7kF > span {
  font-weight: 600 !important;
}

.Button_container__UdOx- {
  display: inline-block;
  border: 2px solid var(--primary);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0;
  text-decoration: none;
  background: var(--pureWhite);
  color: var(--primary);
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
}

.Button_container__UdOx-:hover {
  border: 2px solid var(--primary);
  background: var(--primary);
  color: var(--pureWhite);
}

.Button_delete__3m301 {
  border: 2px solid var(--error);
  color: var(--error);
  width: 100%;
}

.Button_delete__3m301:hover {
  border: 2px solid var(--error);
  background: var(--error);
  color: var(--pureWhite);
}

.Checkbox_container__14LGv {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 1rem;
  -webkit-user-select: none;
          user-select: none;
}
.Checkbox_container__14LGv input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Checkbox_checkmark__1Ot2J {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.1rem;
  width: 1.1rem;
  background-color: var(--hexWhite);
  border: 1px solid var(--hamptonsNewDisabled);
  border-radius: 3px;
}
.Checkbox_checkmark__1Ot2J::after {
  position: absolute;
  display: none;
}

.Checkbox_container__14LGv input:checked ~ .Checkbox_checkmark__1Ot2J {
  background-color: var(--hexWhite);
  border: 1px solid var(--primary);
}

.Checkbox_container__14LGv input:checked ~ .Checkbox_checkmark__1Ot2J::after {
  content: "";
  display: block;
}
.Checkbox_container__14LGv .Checkbox_checkmark__1Ot2J::after {
  left: 0.3rem;
  top: 0;
  width: 0.2rem;
  height: 0.8rem;
  border: 1px solid var(--primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
@media only screen and (max-width: 768px) {
  .Checkbox_checkmark__1Ot2J {
    height: 2rem;
    width: 2rem;
  }
  .Checkbox_container__14LGv .Checkbox_checkmark__1Ot2J::after {
    left: 0.5rem;
    width: 0.7rem;
    height: 1.6rem;
  }
  .Checkbox_container__14LGv input {
    position: unset;
  }
}

.Label_container__fPxFS {
  display: flex;
  flex-direction: column;
}

.Label_container__fPxFS:hover {
  cursor: default;
}

.Label_container__fPxFS > span {
  display: flex;
  align-items: center;
}

.Label_container__fPxFS > span svg {
  margin-left: 0.5rem;
}

.Label_container__fPxFS .Label_errorMessage__yaQ21 {
  color: var(--errorRed);
  display: flex;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
}

.Label_container__fPxFS .Label_errorMessage__yaQ21 strong {
  margin-left: 0.5rem;
}

.Label_clickable__370CM .Label_label__8Ju2T {
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.002em;
}

.Label_notClickable__3A77a {
  color: var(--labelColor);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.002em;
}
.Label_noSponsor__2sHt1 {
  color: var(--labelColor);
  font-style: normal;
  font-weight: 600;
  line-height: 4rem;
  letter-spacing: 0.002em;
}

.Input_container___9blZ input {
  width: 100%;
  border: 2px solid var(--inputGrey);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
}

.Input_container___9blZ input:hover {
  border: 2px solid var(--activeInput);
}
.Input_container___9blZ input:focus-within {
  border: 2px solid var(--activeInput);
}

/* Disabled */
.Input_readOnly__3T1LX input {
  width: 100%;
  border: 2px solid var(--inputGrey);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
  background-color: rgba(225, 225, 225, 0.5);
  color: hsl(0, 0%, 60%);
}

.Input_readOnly__3T1LX input:hover {
  border: 2px solid var(--inputGrey);
  cursor: default;
}
.Input_readOnly__3T1LX input:focus-within {
  border: 2px solid var(--inputGrey);
}

.Input_default__2xB_2 {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.Input_container___9blZ input[class="PhoneInputInput"] {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.Input_dashboardFilter__tPGKs {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.Input_inputError__25PAc input {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.Input_inputError__25PAc input:hover {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.Input_inputError__25PAc input:focus-within {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}

/* Customize the label (the container) */
.Radio_container__1-pW8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  height: 2rem;
  border: 2px solid var(--inputGrey);
  border-radius: 4px;
  padding: 1.5rem;
  width: 100%;
  /* margin-right: 0.5rem; */
  transition: all 0.25s linear;
}

.Radio_container__1-pW8:hover {
  border: 2px solid var(--activeInput);
}
.Radio_container__1-pW8.Radio_readOnly__2A0VV:hover {
  border: 2px solid var(--inputGrey);
  cursor: default;
}

.Radio_container__1-pW8 .Radio_label__1UWRF {
  margin-left: 0.5rem;
  text-wrap: nowrap;
}

/* Hide the browser's default radio button */
.Radio_container__1-pW8 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Radio_readOnly__2A0VV {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  height: 2rem;
  border: 2px solid var(--inputGrey);
  border-radius: 4px;
  padding: 1.5rem;
  width: 100%;
  /* margin-right: 0.5rem; */
  transition: all 0.25s linear;
  background-color: rgba(225, 225, 225, 0.5);
  color: hsl(0, 0%, 60%);
}

.Radio_readOnly__2A0VV:hover {
  border: 2px solid var(--activeInput);
}
.Radio_readOnly__2A0VV.Radio_readOnly__2A0VV:hover {
  border: 2px solid var(--inputGrey);
  cursor: default;
}

.Radio_readOnly__2A0VV .Radio_label__1UWRF {
  margin-left: 0.5rem;
  text-wrap: nowrap;
}

/* Hide the browser's default radio button */
.Radio_readOnly__2A0VV input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.Radio_checkmark__-FX1M {
  display: grid;
  grid-template-columns: 1fr;
}

/* Style the indicator (dot/circle) */
.Radio_checkmark__-FX1M span.Radio_background__XZXLQ {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--primary);
  grid-row-start: 1;
  grid-column-start: 1;
}

.Radio_checkmark__-FX1M span.Radio_unchecked__2Zplh {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--hexWhite);
  grid-row-start: 1;
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
}

.Radio_checkmark__-FX1M span.Radio_uncheckedReadOnly__3IeQp {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: rgba(235, 235, 235, 1);
  grid-row-start: 1;
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
}

.Radio_checkmark__-FX1M span.Radio_checked__4eb3f {
  justify-self: center;
  align-self: center;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  grid-row-start: 1;
  grid-column-start: 1;
}
@media only screen and (max-width: 480px) {
  .Radio_container__1-pW8 {
    display: flex;
    padding: 1.5rem 0.5rem;
    width: 8rem;
  }
}

.RadioGroup_container__9Bnwd {
  display: flex;
  flex-direction: row;
}

.RadioGroup_container__9Bnwd > * {
  margin-right: 1rem;
}
.RadioGroup_container__9Bnwd > :last-child {
  margin-right: 0;
}
@media only screen and (max-width: 480px) {
  .RadioGroup_container__9Bnwd > * {
    margin-right: 0.5rem;
  }
}

.Row_container__uwqRH {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.Row2Items_container__2E4Mm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: end;
}

@media only screen and (max-width: 768px) {
  .Row2Items_container__2E4Mm {
    grid-template-columns: 1fr;
  }
}

.react-select {
  width: 100%;
}

.react-select__control {
  border: 2px solid var(--inputGrey) !important;
  height: 3.3rem;
  padding: 0.5rem 0;
  border-radius: 4px;
}

.react-select__control:hover {
  border-color: var(--activeInput) !important;
}
.react-select__control:focus-within {
  border-color: var(--activeInput) !important;
  box-shadow: none;
}

.react-select__menu {
  margin-top: 2px !important;
  z-index: 6 !important;
}

.react-select__option {
  color: var(--balck);
}

.react-select__option:hover {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
  cursor: pointer;
}

.react-select__option--is-selected {
  background-color: var(--primaryRGB5percent) !important;
  color: var(--black) !important;
}
.react-select__option--is-focused {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
}

.secondary {
  width: 100%;
}

.secondary__control {
  border: none !important;
  height: 3.3rem;
  padding: 0.5rem 0;
  border-radius: 4px;
  color: var(--primary) !important;
}

.secondary__control:hover {
  border-color: var(--activeInput) !important;
}
.secondary__control:focus-within {
  border-color: var(--activeInput) !important;
  box-shadow: none;
}

.secondary__menu {
  margin-top: 2px !important;
  z-index: 6 !important;
}
.secondary__placeholder {
  color: var(--primary) !important;
}
.secondary__indicator > svg {
  fill: var(--primary) !important;
}
.secondary__indicator-separator {
  display: none;
}

.secondary__option {
  color: var(--black);
}

.secondary__option:hover {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
  cursor: pointer;
}

.secondary__option--is-selected {
  background-color: var(--primaryRGB5percent) !important;
  color: var(--black) !important;
}
.secondary__option--is-focused {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
}

.Select_label__2DsuP {
  max-width: -webkit-min-content;
  max-width: min-content;
}

.Select_select__1bvZO {
  padding: 0.3rem;
  margin: 0.2rem 0.2rem;
  border: none;
  color: var(--primary);
  max-width: 12rem;
  font-size: medium;
}
.Select_selected__30zts {
  padding: 0.3rem;
  margin: 0.2rem 0.2rem;
  border: none;
  color: grey;
  max-width: 12rem;
  font-size: medium;
}
.Select_select__1bvZO::after {
  border-color: red;
}

.Select_option___AmrC {
  border: none;
  color: grey;
}
select option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
  color: white;
}

.Select_optionDisabled__1uobA {
  display: none;
}

.PhoneNumberV3_container__W4S3W input {
  width: 100%;
  border: 2px solid var(--inputGrey);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
}

.PhoneNumberV3_container__W4S3W input:hover {
  border: 2px solid var(--activeInput);
}
.PhoneNumberV3_container__W4S3W input:focus-within {
  border: 2px solid var(--activeInput);
}

.PhoneNumberV3_default__p0TB- {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.PhoneNumberV3_container__W4S3W input[class="PhoneInputInput"] {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.PhoneNumberV3_dashboardFilter__1QFP4 {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.PhoneNumberV3_inputError__uBV1y input {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.PhoneNumberV3_inputError__uBV1y input:hover {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.PhoneNumberV3_inputError__uBV1y input:focus-within {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}

.PhoneNumberV3_inputContainer__1PqT3 {
  display: flex;
  width: 100%;
}

.PhoneNumberV3_countryCodeSelect__3L6wy {
  width: 6rem;
  border: 2px solid var(--inputGrey);
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
  padding: 0.2rem;
  margin-right: 0.2rem;
}
.PhoneNumberV3_countryCodeSelect__3L6wy:hover {
  border: 2px solid var(--activeInput);
}
.PhoneNumberV3_countryCodeSelect__3L6wy:focus-within {
  border: 2px solid var(--activeInput);
}
.PhoneNumberV3_inputError__uBV1y select {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.PhoneNumberV3_inputError__uBV1y select:hover {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.PhoneNumberV3_inputError__uBV1y select:focus-within {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}

.Link_spanClick__H9r8x {
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: var(--primary);
  text-decoration: none;
  font-weight: "600";
  cursor: pointer;
  display: flex;
  align-items: center;
}
.Link_clickSupport__31qee {
  cursor: pointer;
  color: var(--primary);
  text-decoration: none;
}

.Link_spanClick__H9r8x:focus {
  outline: none;
}

.Link_atag__E20gq {
  cursor: pointer;
}

.Link_atag__E20gq:focus {
  outline: none;
}

.Link_aLink__iEnj3 {
  cursor: pointer;
  color: var(--grey5);
  text-decoration: none;
}
.Link_activeOverview__3K739 {
  font-weight: 800;
  color: var(--primaryRGB);
}
.Link_activeRents__wUff_ {
  font-weight: 800;
  color: var(--primaryRGB);
}
.Link_termsAndConditions__2hwDg {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--primaryRGB);
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-weight: bold;
}

.Link_disabled__itYdM {
  color: gray;
}
.Link_disabled__itYdM:hover {
  cursor: not-allowed;
  font-weight: 400;
}

.Overlay_container__1Muxt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Overlay_default__10hTB {
  background: rgba(52, 64, 84, 0.7);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

.Overlay_light__1l5Aq {
  background-color: white;
}

.Modal_container__sI89v {
  background: var(--pureWhite);
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .Modal_container__sI89v {
    max-width: none;
    max-height: none;
    margin: 0 1rem;
  }
}

.Modal_container__sI89v header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--primary);
}

.Modal_container__sI89v section {
  margin-top: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.Modal_container__sI89v footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
}

.Modal_close__9MpHR {
  cursor: pointer;
}

.PropertyForm_modal__2d_J5 {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.PropertyForm_modal__2d_J5 > svg {
  align-self: flex-end;
  cursor: pointer;
}

.PropertyForm_crossIcon__AVcKF {
  align-self: flex-end;
  cursor: pointer;
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  right: 15px;
}

.PropertyForm_main__2rX0- {
  padding: 2rem;
  background-color: var(--hexaWhite);
  display: flex;
  justify-content: center;
}

.PropertyForm_buttonSubmit__24Fu5 {
  display: flex;
  justify-content: space-between;
}

.PropertyForm_main__2rX0- svg {
  cursor: pointer;
}

.PropertyForm_buttonSubmit__24Fu5 button:disabled {
  margin-right: 0;
}

.PropertyForm_form__wmE4m {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.PropertyForm_row__1G110 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem;
}

.PropertyForm_error-input__N37vW {
  width: 20rem;
}

.PropertyForm_radio-group__FVHUF {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.PropertyForm_radio__35LAT {
  height: 4rem;
  padding: 0.5rem;
  border: 2px solid var(--inputGrey);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.PropertyForm_radio__35LAT:hover {
  border: 2px solid var(--primary);
  caret-color: var(--primary);
}

.PropertyForm_radio-full__wzxa0 {
  height: 4rem;
  border: 2px solid var(--inputGrey);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.PropertyForm_input__2-mGN {
  margin: 0;
}

.PropertyForm_input__2-mGN input {
  padding: 0;
  padding-left: 1rem;
}

.PropertyForm_form-heading__2zXT5 {
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.02em;
  color: black;
}

.PropertyForm_labelDiv__2Gik0 {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.PropertyForm_label__3lYJG {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--labelColor);
  letter-spacing: 0.002em;
}

.PropertyForm_labelDiv__2Gik0 svg {
  padding-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .PropertyForm_main__2rX0- {
    padding: 2rem 1rem;
  }
  .PropertyForm_buttonSubmit__24Fu5 {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .PropertyForm_buttonSubmit__24Fu5 button {
    width: 100%;
  }

  .PropertyForm_row__1G110 {
    flex-direction: column;
  }

  .PropertyForm_error-input__N37vW {
    width: 95%;
  }
}
@media only screen and (max-width: 500px) {
  .PropertyForm_main__2rX0- {
    padding: 0 2rem 1rem 2rem;
  }
  .PropertyForm_form__wmE4m {
    max-width: 100%;
  }
  .PropertyForm_buttonSubmit__24Fu5 {
    align-items: center;
  }
}

.FilterProperties_container__n0qSO {
  margin-bottom: 1rem;
  border-radius: 6px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  text-align: left;
}

.FilterProperties_titleFilters__3VT7p {
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  color: var(--labelColor);
}
.FilterProperties_clearFiltersContainer__2rkW2 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.FilterProperties_clearFilters__3vB0C {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.FilterProperties_clearFilters__3vB0C > svg {
  width: 0.8rem;
  margin-left: 0.5rem;
}
.FilterProperties_clearFilters__3vB0C > svg > path {
  stroke: var(--labelColor);
}
.FilterProperties_filters__1gxLs {
  background-color: var(--pureWhite);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.FilterProperties_filter__4CWWN {
  max-width: 10rem;
}
.FilterProperties_filtersMobile__29-5E {
  background-color: var(--pureWhite);
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: grid;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
}
.FilterProperties_titleFiltersMobile__3KX2b {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  color: var(--labelColor);
}
.FilterProperties_clearFiltersMobile__2kWjL {
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.FilterProperties_clearFiltersMobile__2kWjL > svg {
  width: 0.8rem;
  margin-left: 0.5rem;
}
.FilterProperties_clearFiltersMobile__2kWjL > svg > path {
  stroke: var(--labelColor);
}
.FilterProperties_icon__NIMmQ {
  cursor: pointer;
  height: 100%;
  width: auto;
}

.FilterProperties_icon__NIMmQ path {
  stroke: var(--primary);
}

.FooterLogo_container__2-9nH {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  max-width: 36rem;
}

.FooterLogo_poweredBy__14pZ3 {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 36rem;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .FooterLogo_container__2-9nH {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .FooterLogo_poweredBy__14pZ3 {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}

input[type="file"].FileInput_fileInput__2UfHL {
  display: none;
}

.ListFileInput_container__1KCkp {
  display: flex;
  flex-direction: column;
}

.ListFileInput_file__2au0x {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.4rem;
  margin-bottom: 0.4rem;
}
.ListFileInput_file__2au0x > svg {
  cursor: pointer;
  margin-left: 2rem;
}

.UploadLayout_container__2KpE5 {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 3px;
  background-color: var(--hexWhite);
  max-width: 100%;
  width: 25rem;
}

.UploadLayout_header__1zSVm {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
}

.UploadLayout_cross__1fBM9 {
  cursor: pointer;
  justify-self: end;
  align-self: center;
}

.UploadLayout_header__1zSVm {
  color: var(--hexWhite);
}

.UploadLayout_logo__1KAZp {
  align-self: center;
  justify-self: center;
}

svg.UploadLayout_cross__1fBM9 path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.UploadLayout_content__1OMjx {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.UploadLayout_button__3DHi4 {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.UploadLayout_button__3DHi4 > button {
  width: 8rem;
}

.UploadLayout_button__3DHi4 > button:nth-child(1) {
  margin-right: 1rem;
}

.UploadLicence_container__3y6kC > * {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.UploadLicence_file__1LA1X {
  display: flex;
  flex-direction: row;
}
.UploadLicence_file__1LA1X > svg {
  cursor: pointer;
  margin-left: 2rem;
}

.UploadLicence_chooseFile__-NnFl {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.LicenceInfo_container__Tl2PS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--listBackground);
  padding: 0.5rem;
  width: 100%;
  margin: 1rem 0rem 1rem 0rem;
}
.LicenceInfo_wrap__f86PF {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.5rem;
}

.LicenceInfo_wrap__f86PF:last-child {
  margin-bottom: 0;
}

.LicenceInfo_documentName__WDknb {
  display: flex;
  align-items: center;
}
.LicenceInfo_documentName__WDknb svg {
  margin-right: 1rem;
}
.LicenceInfo_documentData__1wcZ5 {
  display: flex;
  align-items: center;
}
.LicenceInfo_documentData__1wcZ5 > :nth-child(1),
.LicenceInfo_documentData__1wcZ5 > :nth-child(2) {
  margin-right: 1rem;
  display: flex;
}
.LicenceInfo_documentData__1wcZ5 > :nth-child(3) {
  margin-right: 1rem;
}
.LicenceInfo_toolTipTop__3bI0I {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.2rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
.LicenceInfo_toolTip__34ga0 {
  display: flex;
}
.LicenceInfo_toolTip__34ga0 > svg {
  cursor: pointer;
}

.LicenceInfo_toolTip__34ga0 .LicenceInfo_toolTipText__3kPrG {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 6rem;
  height: 1.8rem;
  margin-top: 1.5rem;
  margin-left: -3.5rem;
}
.LicenceInfo_toolTip__34ga0:hover .LicenceInfo_toolTipText__3kPrG {
  visibility: visible;
}
.LicenceInfo_editButton__XqDD_ {
  cursor: pointer;
}

.LoginLeftSection_loginLeftSection__RHW9K {
  display: flex;
  margin: auto;
  max-width: 100%;
}

.LongToolTip_container__iwRRl {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
}

.LongToolTip_container__iwRRl:hover {
  cursor: default;
}

.LongToolTip_container__iwRRl p {
  margin: 0;
}

.LongToolTip_container__iwRRl span {
  min-width: 2rem;
  min-height: 2rem;
  margin: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d4f2cc;
  color: var(--labelColor);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.3rem;
}

.LongToolTip_red__2IA7H {
  background-color: #fcd2ce !important;
}

.LongToolTip_yellow__14hkF {
  background-color: #fff0c0 !important;
}

.ConfirmActionModal_container__YMZgC {
  padding: 2rem 3rem;
}

.ConfirmActionModal_close__1Zl4x {
  cursor: pointer;
}

.RiskModal_container__3FS-e {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.RiskModal_header__1rf_a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.RiskModal_header__1rf_a svg {
  height: 100%;
  width: auto;
}
.RiskModal_header__1rf_a > :last-child {
  cursor: pointer;
  height: 1.5rem;
  width: auto;
}

.RiskModal_header__1rf_a :nth-child(1),
.RiskModal_header__1rf_a :nth-child(2) {
  margin-right: 1rem;
}

.RiskModal_lists__3m_9B {
  margin-top: 1rem;
  color: var(--primary);
}

.RiskModal_lists__3m_9B > * {
  margin-top: 0.5rem;
}

.RiskModal_footerSection__12htT {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.Pagination_container__TOsvq {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.Pagination_container__TOsvq a {
  color: #1f1f1f;
  border: 1px solid var(--greyPrimary);
  padding: 0.8rem 1rem;
  margin: 0 0.1rem;
  cursor: pointer;
  outline: none;
  background-color: var(--pureWhite);
  font-style: normal;
  font-weight: normal;
  line-height: 1.2rem;
  text-decoration: none;
}

.Pagination_container__TOsvq a.Pagination_active__l3iCs {
  color: var(--pureWhite);
  background-color: var(--primary);
  border: 1px solid var(--grey4);
  box-shadow: inset 0px -3px 0px var(--alertBox);
}

.TermsAndConditions_container__15Ztc {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  color: var(--labelColor);
}

.PickSponsor_container__2hNW2 {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 0rem 2.5rem 3rem 2.5rem;
  align-items: center;
  position: relative;
  max-width: 40rem;
  width: 100%;
  --magic-number: 0.09719;
  --skew-padding: calc(-100vw * var(--magic-number));
}

.PickSponsor_container__2hNW2 > *:not(.PickSponsor_background__3_1ft) {
  z-index: 1;
}
.PickSponsor_backgroundWave__poAoa {
  position: absolute;
  background-size: 100% auto;
}

.PickSponsor_container__2hNW2 img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 3rem;
}

.PickSponsor_background__3_1ft {
  position: absolute;
  z-index: 0;
  background-color: #f0fbfa;
  width: 100%;
  height: 100%;
  transform: skewY(-21deg) translateY(-81%);
}

.PickSponsor_formContainer__2FF5d {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 0 1rem;
}

.PickSponsor_sponsorForm__3cAQJ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 2rem;
  width: 100%;
}
.PickSponsor_title__1_Fv4 {
  margin-top: 2rem;
}
.PickSponsor_subtitle__DAyTE {
  color: var(--primary);
  text-align: center;
}

.PickSponsor_noSponsorsSubtitle__PSLNi {
  color: var(--primary);
  text-align: center;
  margin-bottom: 3rem;
  max-width: 26rem;
}
.PickSponsor_noSponsorsSubtitle__PSLNi svg {
  vertical-align: text-bottom;
  margin-left: 0.3rem;
}
.PickSponsor_noSponsorsSubtitle__PSLNi svg:hover {
  cursor: pointer;
}
.PickSponsor_logo__1zkrK {
  margin-top: 8rem;
  display: flex;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  flex-direction: column;
  align-items: center;
}

.PickSponsor_sponsor__3rgw6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-top: 0.37rem solid var(--primaryRGB);
  background: var(--hexWhite);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.PickSponsor_sponsor__3rgw6:hover {
  cursor: pointer;
  box-shadow: 0px 4px 10px var(--primaryRGB70), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.PickSponsor_sponsor__3rgw6.PickSponsor_selected__2dnIE {
  box-shadow: 0px 4px 4px var(--primaryRGB70), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.PickSponsor_premiumSponsor__2lARC {
  padding: 1.8rem;
  border-top: 0.37rem solid var(--secondaryRGB);
}

.PickSponsor_premiumSponsor__2lARC:hover {
  box-shadow: 0px 4px 10px var(--secondaryRGB70),
    0px 4px 4px rgba(0, 0, 0, 0.25);
}

.PickSponsor_premiumSponsor__2lARC.PickSponsor_selected__2dnIE {
  box-shadow: 0px 4px 4px var(--secondaryRGB70), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.PickSponsor_secondaryProviders__1OeYH {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.PickSponsor_secondaryProviders__1OeYH > * {
  flex-grow: 1;
  max-width: 32%;
}
.PickSponsor_suggestSponsorText__jTRLv {
  margin-bottom: 1rem;
  color: var(--grey5);
  text-align: center;
}
.PickSponsor_container__2hNW2 footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.PickSponsor_noSponsorFooter__1XPaB {
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.PickSponsor_noSponsorFooter__1XPaB > * {
  width: 100%;
}

.PickSponsor_suggestSponsorButtonText__3ZROZ {
  text-align: center;
}

.PickSponsor_bottomText__CbHZE {
  text-align: center;
  line-height: 1rem;
}
.PickSponsor_termsAndConditions__21Sge {
  white-space: nowrap;
}
@media only screen and (max-width: 580px) {
  .PickSponsor_container__2hNW2 {
    padding: 0 1rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .PickSponsor_logo__1zkrK {
    margin-top: 1rem;
  }

  .PickSponsor_container__2hNW2 footer {
    margin-top: unset;
    margin-bottom: 1rem;
  }
  .PickSponsor_termsAndConditions__21Sge {
    margin-bottom: 2rem;
  }
  .PickSponsor_termsAndConditions__21Sge > div > span {
    flex-wrap: wrap;
  }
}

.PropertyAction_container__1aqpu {
  width: 100%;
  display: inline-block;
  position: relative;
}
.PropertyAction_container__1aqpu p {
  margin: 0;
}

.PropertyAction_container__1aqpu > button {
  width: 100%;
  height: 2rem;
  color: var(--labelColor);
}

.PropertyAction_container__1aqpu > button svg path {
  stroke: var(--black);
}

.PropertyAction_container__1aqpu .PropertyAction_menu__33jHP {
  color: var(--labelColor);
  right: 0%;
  width: -webkit-max-content;
  width: max-content;
  outline: none;
  z-index: 3;
  position: absolute;
  border-radius: 0.8rem;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  font-style: normal;
  font-weight: normal;
  background-color: var(--pureWhite);
}

.PropertyAction_container__1aqpu .PropertyAction_menu__33jHP > * {
  padding: 1rem;
}

.PropertyAction_view__2d87H {
  color: var(--primary);
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem 0.8rem 0 0;
  transition: all 0.5s;
}

.PropertyAction_view__2d87H svg path {
  stroke: var(--primary);
}

.PropertyAction_view__2d87H:hover {
  color: var(--pureWhite);
  background-color: var(--primary);
}

.PropertyAction_view__2d87H:hover svg path {
  stroke: var(--pureWhite);
}

.PropertyAction_view__2d87H :first-child {
  margin-right: 0.5rem;
}

.PropertyAction_delete__XOrfQ {
  color: var(--error);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.8rem 0.8rem;
  transition: all 0.5s;
}
.PropertyAction_delete__XOrfQ :first-child {
  margin-right: 0.5rem;
}
.PropertyAction_delete__XOrfQ:hover {
  color: var(--pureWhite);
  background-color: var(--error);
}

.PropertyAction_delete__XOrfQ:hover svg path {
  stroke: var(--pureWhite);
}

.UploadCertificate_row__2sX8H {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.UploadCertificate_chooseFile__GVyJl {
  margin-top: 2rem;
}

.Flex_container__2QLyF {
  display: flex;
}

.Flex_row__OeKZp {
  flex-direction: row;
}

.Flex_column__cxzdS {
  flex-direction: column;
}

.MessageBanner_message__PEVpl {
  display: flex;
  justify-content: center;
  background-color: var(--warningColor);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.9rem 1.1rem;
  line-height: 1.1rem;
}
.MessageBanner_message__PEVpl svg {
  margin-right: 1.1rem;
}

.TooltipModal_container__2L3cY {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
}

.TooltipModal_container__2L3cY > svg {
  align-self: flex-end;
  position: fixed;
  cursor: pointer;
}

.body-class {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
  background: #ededed;
  min-height: 100vh;
  margin: 0;
  color: #333333;
}

.container {
  width: 100%;
  max-width: 842px;
  margin: 0 auto;
  height: auto;
  background: #ededed;
  padding: 24px;
  box-sizing: border-box;
}

.content-row {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}

.left-col {
  max-width: 320px;
  width: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
}
.details {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  min-height: 20px;
  line-height: 24px;
  margin-bottom: 23px;
}
.address-col {
  background: #1b294a;
  padding: 16px 18px;
}

.address-box {
  display: flex;
  margin-bottom: 16px;
}

.address-box:last-child {
  margin-bottom: 0;
}

.address-text {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  font-weight: 600;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
}

.full-head {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ededed;
}

.head-icon {
  line-height: 0;
  margin-right: 17px;
}
.license-icon {
  line-height: 0;
  margin-top: 2px;
  margin-right: 8px;
}
.head-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #1b294a;
  font-weight: 600;
}
.logo-image {
  max-width: 122px;
  max-height: 40px;
}
.logo-image-white {
  padding: 10px 16px 10px 16px;
  border-radius: 20px;
  background: #f4d100;
}

.mandatory-obj-col {
  padding: 8px 16px;
}

.mandatory-obj-options {
  border-bottom: 1px solid #ededed;
  display: flex;
  padding: 9.5px 0px;
}

.mandatory-obj-options:last-child {
  border-bottom: none;
}

.object {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  flex-basis: 55%;
  max-width: 55%;
}

.length {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  font-weight: 600;
  flex-basis: 45%;
  max-width: 45%;
}

.right-col {
  max-width: 458px;
  height: auto;
  width: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
}

.proposal-col {
  padding: 4px 16px;
}

.heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 280px;
  letter-spacing: 0.002em;
}
.heading-empty {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.002em;
}

.price {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  font-weight: 600;
  margin-right: 5px;
}
.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
  margin-top: 8px;
}

.proposal-options {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ededed;
  padding: 13.5px 0;
}

.proposal-options:last-child {
  border-bottom: none;
}

.proposal-col-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.proposal-col-head-certificate {
  display: flex;
  flex-direction: row;
  color: #1b294a;
  width: 100%;
  height: auto;
  min-height: 24px;
  margin-left: 12px;
}
.proposal-col-head-certificate.price-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-estimate-box {
  padding: 8px 16px;
  background: #333333;
}

.estimate-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 7.5px 0;
}

.estimate-heading,
.estimate-price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #ffffff;
}

.total-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0;
}

.total {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #ffffff;
  font-weight: 600;
}

.total-price {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
}

.powered-col {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.powered-col img {
  margin-left: 9px;
}

@media (max-width: 991px) {
  .content-row {
    justify-content: space-between;
  }

  .left-col {
    max-width: 320px;
  }

  .right-col {
    max-width: 458px;
  }
}

.UploadDocument_container__3Msnu {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 0.3rem;
  background-color: var(--hexWhite);
}

.UploadDocument_header__2X0M2 {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
}

.UploadDocument_header__2X0M2 > h6 {
  color: var(--hexWhite);
  font-family: var(--fontFamily);
}

.UploadDocument_logo__2L8oX {
  justify-self: center;
}

svg.UploadDocument_cross__3fFBe {
  justify-self: end;
}

svg.UploadDocument_cross__3fFBe path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.UploadDocument_Wrap__2zGjT {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.UploadDocument_row__3Eaf8 {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.UploadDocument_ChooseFile__1JDtz {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.UploadDocument_button__1d5t8 {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.UploadDocument_button__1d5t8 > button {
  width: 8rem;
}

.UploadDocument_button__1d5t8 > button:nth-child(1) {
  margin-right: 1rem;
}

.packages_crossIcon__2_yML {
  display: flex;
  padding-top: 1rem;
  padding-right: 3rem;
  justify-content: flex-end;
}
.packages_crossIcon__2_yML > svg {
  cursor: pointer;
}
.packages_main__13Wg- {
  background: var(--hexWhite);
  margin: 0 1rem;
  /* font-Family: fonts.primary; */
  font-style: normal;
  padding: 0;
}

.packages_popular__2cYFf {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.packages_mostPopular__2Cmjk {
  background: var(--hamptonsSecondary);
  border-radius: 6px 6px 0px 0px;
  color: var(--pureWhite);
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding: 0 1rem;
}

.packages_packageList__3cCYg {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
}

.packages_packageCommon__2l7GV {
  display: flex;
  width: 150px;
  flex-direction: column;
  min-height: 1rem;
  justify-content: space-around;
  align-items: center;
}

.packages_packageHeading__1neqF {
  background: var(--bg6);
  min-height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--labelColor);
}
.packages_packageHeading__1neqF .packages_packageTitle__2a-M9 {
  font-size: 20px;
}
.packages_packageHeading__1neqF .packages_selected__3w_mM {
  background: var(--primary);
  min-height: 76px;
}
.packages_packageHeading__1neqF .packages_selected__3w_mM span {
  color: var(--hexaWhite);
}
.packages_packageTitle__2a-M9 {
  width: 250px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  height: auto;
  padding: 0;
  align-items: center;
  color: var(--grey1);
}

.packages_packageContent__22uyF {
  min-height: 50px;
  width: 100%;
}
.packages_packageContent__22uyF .packages_packageList__3cCYg {
  height: 2.2rem;
  box-shadow: inset 0px -1px 0px var(--grey4);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.packages_packageContent__22uyF .packages_packageList__3cCYg .packages_packageSelectedList__2ynTs {
  height: 2.2rem;
  background: var(--bg6);
  box-shadow: inset 0px -1px 0px var(--grey4);
}

.packages_select__aA9Dz {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 16px;
  margin-top: 20px;
  border: 2px solid #dfe3e8;
  box-sizing: border-box;
  border-radius: 4px;
}

.packages_packageActions__2I4CV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.packages_packageHelp__drDFi {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 24px;
}
.packages_packageSupport__1EKaY {
  font-size: 18px;
  font-weight: 500;
  margin-left: 0.5rem;
  line-height: 24px;
  color: var(--primary);
}
.packages_packageSupportEmail__2H3u0 {
  font-size: 18px;
  font-weight: 600;
  margin-left: 3px;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}
.packages_button__l3eVW {
  display: flex;
}
.packages_button__l3eVW :nth-child(2) {
  margin-left: 1rem;
}
button {
  width: 160px;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}
.packages_selectedPackage__31vab {
  display: flex;
  justify-content: center;
  align-items: center;
}
.packages_selectedPackage__31vab :nth-child(1) {
  margin-right: 0.5rem;
}
.packages_selectedText__hGikw {
  color: var(--pureWhite) !important;
}
.packages_selectedText__hGikw svg {
  width: 14px;
  margin-right: 3px;
}

.packages_packageName__2n-iI {
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin-top: 7px;
}
.packages_disabled__LC3dB {
  pointer-events: none;
}
.packages_packageLink__lQfvC {
  color: var(--primary);
  font-weight: bold;
  font-size: 14px;
  line-height: 125%;
}

.packages_packageFooter__3TGtG {
  background: var(--grey4);
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.packages_packageFooter__3TGtG .packages_locationName__3jrdR {
  font-size: 14px;
  font-weight: bold;
  margin-left: 18px;
  width: 250px;
}

footer {
  margin-top: 10px;
  font: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey1);
}
.packages_wrapper__1Vcp- {
  margin: 1rem 2rem;
}
.packages_mobileContainer__33PWR {
  border-radius: 1rem;
  padding-bottom: 0.5rem;
  background-color: var(--hexWhite);
}
.packages_mobilePackages__2TCc4 {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  color: var(--hexWhite);
  border-radius: 1rem 1rem 0 0;
}
.packages_mobilePackages__2TCc4 > svg {
  height: 1rem;
}
.packages_mobilePackages__2TCc4 > svg > path {
  stroke: var(--hexWhite);
}
.packages_mobileSelect__15xxy {
  padding: 1rem 2rem;
  background-color: var(--hamptonsNewDisabled);
}
.packages_mobileDescription__1yqPQ {
  margin: 1rem 2rem;
}
.packages_mobileDescriptionTitle__3ddYi {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 1rem;
}
.packages_mobilePackageList__3biAs {
  height: 2.2rem;
  box-shadow: inset 0px -1px 0px var(--grey4);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.packages_tickIcon__3SCoq {
  box-shadow: inset 0px -1px 0px var(--grey4);
  display: flex;
  align-items: center;
  width: 5rem;
  justify-content: center;
  height: 2.2rem;
  color: black;
  background: var(--bg6);
}
.packages_mobileDescriptionText__1irdW {
  color: var(--grey5);
  margin-bottom: 1rem;
}
.packages_mobilePrice__D6D-D {
  margin: 0 2rem 0rem 2rem;
  border-bottom: 2px solid var(--grey4);
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.packages_mobileSelectedPackage__33C2u {
  padding: 2rem 1rem;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  background-color: var(--bg6);
  color: var(--black);
  margin: 0 2rem 1rem 2rem;
  border-radius: 0rem 0rem 1rem 1rem;
  align-items: center;
}
.packages_mobileSelectedPackage__33C2u button {
  width: 6rem;
  margin-right: 0;
}
.packages_selectPackage__1YJvG {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.packages_selectedPackageName__e0uxI {
  font-weight: 400;
}
.packages_viewDetails__Np1eR {
  margin-top: 1rem;
  color: var(--primary);
}
.packages_viewDetails__Np1eR :active {
  font-weight: bold;
}

.Header_header__UQ3XW {
  width: 100%;
  height: 72px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: var(--hexaWhite);
  /* align-items: center; */
}
.Header_burger__zksrb {
  margin: 1.5rem;
}
.Header_slidePaneInfo__1t4iX {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--primaryRGB10);
}
.Header_slidePaneInfo__1t4iX > svg {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
}

.Header_address__3HcHy {
  display: flex;
  justify-content: space-between;
  padding: 0;
  color: var(--labelColor);
}
.Header_logo__1ZFfH {
  display: flex;
  width: 100%;
  margin-right: 1rem;
  justify-content: space-between;
  align-items: center;
}
.Header_logo__1ZFfH > img {
  max-width: 8rem;
  height: 44px;
  width: auto;
}
.Header_navbar__30nq7 {
  color: var(--labelColor);
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.Header_mobileMenu__112nW {
  width: 70%;
  display: block;
  border-top-right-radius: 3rem 3rem;
  border-bottom-right-radius: 3rem 3rem;
  margin-top: 2rem;
  padding-top: 3rem;
  background-color: var(--hexaWhite);
  box-shadow: 1px 1px var(--labelColor);
  transform: translateX(100%);
}

.Header_closeIcon__2eyDK {
  display: flex;
  padding: 0.5rem;
}
.Header_tabviewTitle__Dup_r {
  color: var(--labelColor);
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.Header_tabview__2iAZL {
  color: var(--labelColor);
  display: flex;
  align-items: center;
  margin: 0rem 0 0.5rem 0.5rem;
}
.Header_tabview__2iAZL > a {
  color: var(--labelColor);
  display: flex;
  text-decoration: none;
  align-items: center;
}
.Header_tabviewActive__2fKBp > a > svg > path {
  stroke: var(--primary);
}
.Header_tabviewActive__2fKBp > a > svg path[class="propertyIcon"] {
  stroke: var(--hamptonsSecondary);
}
.Header_tabviewActive__2fKBp > a > svg path[class="homeIcon"] {
  stroke: var(--hamptonsSecondary);
}
.Header_tabviewActive__2fKBp > a > svg path[class="planIcon"] {
  stroke: var(--hamptonsSecondary);
}
.Header_tabviewDetails__1oyJW {
  border-radius: 0.8rem;
  color: var(--primary);
  padding: 0.4rem 1rem;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  margin: 0.5rem;
}
.Header_tabviewDetails__1oyJW :hover {
  cursor: pointer;
}

.Header_arrowActive__QwGO4 > svg > path {
  stroke: var(--hamptonsSecondary);
  fill: var(--hamptonsSecondary);
}
.Header_propertyReportPathActive__28jgu {
  margin: 0.2rem 0 0.5rem 0;
}
.Header_propertyReportPathActive__28jgu > a {
  text-decoration: none;
  border-radius: 0.8rem;
  color: var(--primary);
  padding: 0.4rem 1rem;
  background-color: #f0fbfa;
  display: flex;
  align-items: center;
}
.Header_propertyReportPathActive__28jgu > a > svg {
  margin-right: 0.5rem;
  width: 16px;
  max-width: 16px;
}
.Header_propertyReportPathActive__28jgu > a > svg > circle {
  fill: var(--primary);
}
.Header_propertyReportPath__2vWm2 {
  margin: 0.2rem 0 0.5rem 0;
}
.Header_propertyReportPath__2vWm2 > a {
  text-decoration: none;
  border-radius: 0.8rem;
  color: var(--primary);
  padding: 0.4rem 1rem;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
}
.Header_propertyReportPath__2vWm2 > a > svg {
  margin-right: 0.5rem;
  width: 16px;
  max-width: 16px;
}
.Header_breadcrumbMobile__1utmG {
  color: var(--labelColor);
  display: flex;
}
.Header_powered__2z62- {
  margin-right: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: var(--hamptonsSecondary);
  text-decoration: none;
}
.Header_viewDetails__WCO1Y {
  display: flex;
}
@media (max-width: 768px) {
  .Header_header__UQ3XW {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Header_image-logo__2eHbL {
    width: 90px;
    height: 36px;
  }
  .Header_logo__1ZFfH {
    padding: 14px;
  }
}

.BreadcrumbMobile_container__2CFwW {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--hexWhite);
  margin-bottom: 1rem;
}

.BreadcrumbMobile_container__2CFwW > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--primary);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--bg2);
}

.BreadcrumbMobile_container__2CFwW > header :first-child {
  margin-right: 1rem;
}

.BreadcrumbMobile_homeIcon__eqZkB > path {
  stroke: var(--primary);
}

.BreadcrumbMobile_container__2CFwW > header svg {
  height: 100%;
  width: auto;
}

.BreadcrumbMobile_link__2qMA2 {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: gray;
}
.BreadcrumbMobile_selectedLink__1q5Zh {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--primary);
}
.BreadcrumbMobile_selected__206qa {
  display: flex;
  align-items: center;
  background-color: #f0fbfa;
  padding: 0.2rem 1rem;
  border-radius: 0.8rem;
}
.BreadcrumbMobile_selected__206qa > svg {
  width: 16px;
  min-width: 16px;
}
.BreadcrumbMobile_notSelected__2_1Fr {
  display: flex;
  align-items: center;
  background-color: #f9fafb;
  padding: 0.2rem 1rem;
  border-radius: 0.8rem;
}
.BreadcrumbMobile_notSelected__2_1Fr > svg {
  width: 16px;
  min-width: 16px;
}
.BreadcrumbMobile_circleLink__3_Ch0 {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
}
.BreadcrumbMobile_circleLink__3_Ch0 > svg {
  min-width: 22px;
}

.BreadcrumbMobile_checked__3gGpo > circle {
  fill: var(--primary);
}

.BreadcrumbMobile_circleLink__3_Ch0 :nth-child(1) {
  margin-right: 0.5rem;
}
.BreadcrumbMobile_circleLink__3_Ch0 :nth-child(2) {
  margin-right: 0.5rem;
}
.BreadcrumbMobile_bcsteps__3cAZE {
}
.BreadcrumbMobile_step__3UWYZ {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #919eab;
  flex-direction: column;
}
.BreadcrumbMobile_innerStep__2fDw7 {
  margin: 0.5rem 0;
  display: flex;
  width: 300px;
  flex-direction: row;
  padding-left: 1rem;
}

.BreadcrumbMobile_step__3UWYZ svg circle {
  stroke: var(--primary);
}

.BreadcrumbMobile_step__3UWYZ .BreadcrumbMobile_firstColumn__1pXcd {
  justify-self: center;
}

.BreadcrumbMobile_navButton__2AZgQ {
  display: none;
}

.BreadcrumbMobile_unstuck__3OrW5 {
  display: none;
}
.BreadcrumbMobile_stuck__25lRG {
  display: none;
}

.BreadcrumbMobile_infoTip__1orNo {
  display: none;
  position: relative;
  max-width: 15rem;
  transition: 1s;
}
.BreadcrumbMobile_dropdown__2zPsF:hover .BreadcrumbMobile_infoTip__1orNo {
  display: block;
  position: absolute;
  color: black;
  margin-left: 2rem;
  margin-top: -3rem;
  background-color: var(--pureWhite);
  border: solid 2px var(--grey4);
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 15rem;
}

.BusinessUpgrade_container__3ZXls {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.BusinessUpgrade_header__JnXO1 {
  display: flex;
  flex-direction: column;
}

.Spinner_container__1GEv- {
  width: 3rem;
  height: 3rem;
}

svg.Spinner_container__1GEv- {
  stroke: var(--primary);
}

.NetworkError_container__1x_4G {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 0rem 2rem;
  align-items: center;
  position: relative;
  max-width: 37rem;
  width: 100%;
  --magic-number: 0.09719;
  --skew-padding: calc(-100vw * var(--magic-number));
}

.NetworkError_container__1x_4G > *:not(.NetworkError_background__369ID) {
  z-index: 999;
}
.NetworkError_backgroundWave__XpRG3 {
  position: absolute;
}
.NetworkError_backgroundWave__XpRG3 > svg > path {
  fill: var(--lightRed);
}

.NetworkError_networkMessage__1hKQB {
  height: 15rem;
  max-width: 20rem;
  display: flex;
  align-items: center;
}
.NetworkError_message__26IoR {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NetworkError_message__26IoR > h1 {
  margin-bottom: 2rem;
  color: var(--red);
}

.JourneyLayout_container__2nqci {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--hexWhite);
  padding: 1rem;
  color: var(--labelColor);
}

.JourneyLayout_container__2nqci > *:not(:last-child) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.JourneyLayout_container__2nqci > header {
  color: var(--primary);
  border-bottom: 2px solid var(--bg2);
}
.JourneyLayout_h6PropertyBox__3B8S6 {
  display: flex;
  justify-content: space-between;
}

.JourneyLayout_button__19lBg {
  display: flex;
  justify-content: space-between;
}

.JourneyLayout_title__tAPex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px;
}

@media only screen and (max-width: 768px) {
  .JourneyLayout_container__2nqci {
    padding: 0.7rem;
  }
  .JourneyLayout_UsefulInfoAndDownloads__NEz2W {
    display: none;
  }
}

.BuildingCertification_heading__3aUoA {
  color: var(--primary);
}

.BuildingCertification_noteText__x1gZI {
  color: var(--labelColor);
}
.BuildingCertification_requirementList__1T4cL {
  color: var(--labelColor);
}

.BuildingCertification_modalContainer__Mjlx2 > span {
  padding: 0.2rem;
}

.CreateBuildingReport_buildingReportContainer__2ezIR {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.AddIcon_container__1o4_W {
  cursor: pointer;
  position: relative;
  display: flex;
}
.AddIcon_container__1o4_W:hover > .AddIcon_tooltip__1Z1Ky {
  visibility: visible;
}

.AddIcon_container__1o4_W.AddIcon_disabled__1EVJV {
  cursor: unset;
}

.AddIcon_container__1o4_W.AddIcon_disabled__1EVJV > svg path {
  stroke: grey;
  fill: rgb(228, 228, 228);
}

.AddIcon_tooltip__1Z1Ky {
  top: 28px;
  left: -67px;
  color: var(--hexWhite);
  width: 10rem;
  height: 2.1rem;
  display: flex;
  padding: 5px 0px;
  z-index: 1;
  position: absolute;
  box-shadow: rgb(0 0 0 / 9%) 0px 1px 4px;
  min-height: 30px;
  text-align: center;
  visibility: hidden;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--primary);
}

.ExpandIcon_container__1hU6R {
  display: flex;
  align-items: center;
  height: 100%;
}

.ExpandIcon_icon__2lzoY {
  cursor: pointer;
  height: 100%;
  width: auto;
}

.ExpandIcon_icon__2lzoY path {
  stroke: var(--primary);
}

.ExpandIcon_container__1hU6R .ExpandIcon_toolTipText__1FBEN {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
  margin-left: -4.5rem;
}
.ExpandIcon_container__1hU6R:hover .ExpandIcon_toolTipText__1FBEN {
  visibility: visible;
}

.Certificate_container__2-ZK9 {
  background-color: var(--backgroundColorSecondary);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
}

.Certificate_container__2-ZK9 main {
  margin-top: 1rem;
}

.Certificate_container__2-ZK9 header {
  display: flex;
  justify-content: space-between;
}
.Certificate_topModal__3nkON {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.Certificate_crossIcon__3wzSj {
  cursor: pointer;
}
.Certificate_modalSnippet__3ceFF {
  color: var(--labelColor);
}
.Certificate_certification__uE6GP {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Certificate_certification__uE6GP :nth-child(1) {
  margin-right: 0.2rem;
}
.Certificate_licenseName__1DaBt {
  max-width: 30rem;
}
.Certificate_licensePrice__1CEeY {
  min-width: 6rem;
}
.Certificate_actionGroup__1j2xA {
  display: flex;
}

.Certificate_actionGroup__1j2xA > * {
  cursor: pointer;
  margin-left: 0.6rem;
}
.Certificate_toolTipInfo__36I4f {
  display: flex;
  align-items: center;
}

.Certificate_toolTipInfo__36I4f .Certificate_toolTipInfoText__3K6uM {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 6rem;
  height: 1.8rem;
  margin-top: 4rem;
  margin-left: -2.5rem;
}
.Certificate_toolTipInfo__36I4f:hover .Certificate_toolTipInfoText__3K6uM {
  visibility: visible;
}
.Certificate_toolTip__AJFSz {
  display: flex;
  align-items: center;
}

.Certificate_toolTip__AJFSz .Certificate_toolTipText__39MrI {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
  margin-left: -4.5rem;
}
.Certificate_toolTip__AJFSz:hover .Certificate_toolTipText__39MrI {
  visibility: visible;
}
.Certificate_addIcon__20FGx {
  display: flex;
  align-items: center;
}

.Certificate_uploadSection__3XEDk {
  background-color: var(--hexWhite);
  padding: 1rem;
  margin-top: 0.5rem;
}

.Certificate_uploadSection__3XEDk main {
  padding-left: 0.5rem;
}
.Certificate_expand__QgjmG {
  cursor: pointer;
}
.Certificate_documentInfo__3JnZ6 {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 1rem;
}

.Certificate_documentInfo__3JnZ6 > :nth-child(1) {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 0.3rem;
  column-gap: 0.3rem;
  text-decoration: none;
  color: inherit;
}
.Certificate_documentInfo__3JnZ6 > :nth-child(2) {
  justify-self: end;
}
.Certificate_actionGroup__1j2xA {
  color: var(--primary);
  max-height: 1.6rem;
}
.Certificate_checkbox__1z5Yw {
  margin-top: 0.5rem;
  display: flex;
}
.Certificate_checkbox__1z5Yw > :nth-child(1) {
  width: unset;
  margin-right: 0.5rem;
}
.Certificate_referenceDoc__3ihqX {
  display: flex;
  align-items: center;
}

.Certificate_editDoc__3IbJg {
  cursor: pointer;
}
.Certificate_expiryDate__3twNW {
  cursor: unset;
  color: var(--black);
}

.Certificate_markdownContainer__MQBQ2 {
  padding: 1rem 1.5rem;
}

.EPCGraph_container__kAxdm {
  padding: 0rem;
}
.EPCGraph_expired__6Ee1x {
  color: var(--red);
}
.EPCGraph_expiresSoon__1i3lj {
  color: var(--warningColor);
}
.EPCGraph_chartContainer__Jx7QV {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.EPCGraph_chart__2yPES {
  padding: 1rem;
  width: 48%;
  margin: 1%;
  min-width: 300px;
  max-width: 500px;
  border: solid;
  border-width: 1px;
  border-radius: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.EPCGraph_borderRadius__FG29a {
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.EPCGraph_bars__3DauY + .EPCGraph_bars__3DauY {
  border-top: 0;
}
.EPCGraph_bars__3DauY.EPCGraph_noborder_bottom__3N_qi {
  border-bottom: 0;
}
.EPCGraph_bars__3DauY .EPCGraph_noborder_top__1ObpS {
  border-top: 0;
}
.EPCGraph_bars__3DauY {
  display: grid;
  position: relative;
  min-width: 300px;
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE {
  display: flex;
  margin-bottom: 2px;
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE .EPCGraph_th__1MGkK {
  padding: 5px;
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE div {
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE .EPCGraph_col_1__3d-7h {
  width: 64%;
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE .EPCGraph_col_2__nI3Mm,
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE .EPCGraph_col_3__2neIo {
  width: auto;
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE .EPCGraph_block__2Sbv- {
  margin-bottom: 0px;
  padding: 0 4px;
  min-height: 30px;
}
.EPCGraph_bars__3DauY .EPCGraph_bar__3tTjE .EPCGraph_block__2Sbv-:last-child {
  margin-bottom: 0;
}
.EPCGraph_bars__3DauY .EPCGraph_range__2N5mK {
  color: #000;
  position: relative;
  font-weight: 700;
  margin-left: 0.1rem;
}
.EPCGraph_bars__3DauY .EPCGraph_title__3E-nt {
  color: #000;
  float: right;
  font-weight: 700;
  text-align: center;
  margin-right: 0.4rem;
}
.EPCGraph_bars__3DauY small {
  display: block;
  line-height: 1em;
  opacity: 0.5;
  padding: 8px 4px;
}
.EPCGraph_bars__3DauY .EPCGraph_pointer__3l15n.EPCGraph_current_only__1cVS_ {
  left: 80%;
}
.EPCGraph_bars__3DauY .EPCGraph_pointer__3l15n.EPCGraph_current__1iI3v {
  left: 70%;
}
.EPCGraph_bars__3DauY .EPCGraph_pointer__3l15n.EPCGraph_potential__3FvNr {
  left: 88%;
}
.EPCGraph_bars__3DauY .EPCGraph_pointer__3l15n {
  display: block;
  margin: 0;
  position: absolute;
}
.EPCGraph_bars__3DauY .EPCGraph_pointer__3l15n span {
  color: #000;
  font-weight: 700;
  padding: 2px 8px;
  position: relative;
}
.EPCGraph_bars__3DauY .EPCGraph_pointer__3l15n span:after {
  border: solid transparent;
  border-width: 18px;
  content: " ";
  height: 12;
  margin-top: -18px;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 50%;
  width: 0;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_a_plus__3y0DS span:after,
.EPCGraph_bars__3DauY .EPCGraph_rating_a__3swUm span:after {
  border-right-color: #008054;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_b__37Rz5 span:after {
  border-right-color: #19b459;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_c__2nD_B span:after {
  border-right-color: #8dce46;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_d__2m3tm span:after {
  border-right-color: #ffd500;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_e__2xwDT span:after {
  border-right-color: #fcaa65;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_f__refd5 span:after {
  border-right-color: #ef8023;
}
.EPCGraph_bars__3DauY .EPCGraph_rating_g__1iQuS span:after {
  border-right-color: #e9153b;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_a_plus__1liGD span:after,
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_a__1EfPI span:after {
  border-right-color: #cbe4f3;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_b__3QnBU span:after {
  border-right-color: #98bfe6;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_c__ioQst span:after {
  border-right-color: #72a2d6;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_d__1y0Ls span:after {
  border-right-color: #4d85c2;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_e__DO2HX span:after {
  border-right-color: #a6a6a8;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_f__2vMui span:after {
  border-right-color: #939094;
}
.EPCGraph_bars__3DauY .EPCGraph_impact_rating_g__tAgxA span:after {
  border-right-color: #827f82;
}
.EPCGraph_rating_a__3swUm {
  background: #008054;
  margin-right: 70%;
}
.EPCGraph_rating_b__37Rz5 {
  background: #19b459;
  margin-right: 60%;
}
.EPCGraph_rating_c__2nD_B {
  background: #8dce46;
  margin-right: 50%;
}
.EPCGraph_rating_d__2m3tm {
  background: #ffd500;
  margin-right: 40%;
}
.EPCGraph_rating_e__2xwDT {
  background: #fcaa65;
  margin-right: 30%;
}
.EPCGraph_rating_f__refd5 {
  background: #ef8023;
  margin-right: 20%;
}
.EPCGraph_rating_g__1iQuS {
  background: #e9153b;
  margin-right: 10%;
}
.EPCGraph_impact_rating_a__1EfPI {
  background: #cbe4f3;
  margin-right: 70%;
}
.EPCGraph_impact_rating_b__3QnBU {
  background: #98bfe6;
  margin-right: 60%;
}
.EPCGraph_impact_rating_c__ioQst {
  background: #72a2d6;
  margin-right: 50%;
}
.EPCGraph_impact_rating_d__1y0Ls {
  background: #4d85c2;
  margin-right: 40%;
}
.EPCGraph_impact_rating_e__DO2HX {
  background: #a6a6a8;
  margin-right: 30%;
}
.EPCGraph_impact_rating_f__2vMui {
  background: #939094;
  margin-right: 20%;
}
.EPCGraph_impact_rating_g__tAgxA {
  background: #827f82;
  margin-right: 10%;
}
.EPCGraph_rating_a__3swUm,
.EPCGraph_rating_b__37Rz5,
.EPCGraph_rating_c__2nD_B,
.EPCGraph_rating_d__2m3tm,
.EPCGraph_rating_e__2xwDT,
.EPCGraph_rating_f__refd5,
.EPCGraph_rating_g__1iQuS {
  color: #111;
}
.EPCGraph_rating__kKMvr {
  font-weight: 400;
  line-height: 1em;
  margin-bottom: 1px;
  margin-bottom: 0.1rem;
  padding: 5px;
  padding: 0.5rem;
}
.EPCGraph_rating__kKMvr input {
  margin: 0;
}
.EPCGraph_rating__kKMvr label {
  cursor: pointer;
  display: block;
  padding-left: 4px;
  padding-left: 0.4rem;
  text-align: right;
  width: 100%;
}
.EPCGraph_rating__kKMvr label span {
  display: inline-block;
  float: left;
}
.EPCGraph_rating__kKMvr strong {
  color: #fff;
  -webkit-text-stroke: 1px #111;
}
.EPCGraph_ratingsContainer__26NPY {
  display: flex;
  flex-direction: row;
}
.EPCGraph_ratingContainer__14x6Q {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #dfe9f4;
  padding: 0.8rem;
  margin: 0.2rem;
  margin-bottom: 1rem;
  width: 50%;
  border-radius: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.EPCGraph_ratingSvg__2QQeb {
  padding-right: 1rem;
}
.EPCGraph_noEPCContainer__3tVfk {
  padding: 9px;
}
.EPCGraph_noEPCBox__YnomJ {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 13pt;
  color: #637381;
  width: 100%;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.EPCGraph_noEPCBox__YnomJ > h5 {
  padding-top: 10px;
}
@media only screen and (max-width: 1314px) {
  .EPCGraph_chart__2yPES {
    width: 100%;
  }
}

.Common_disclaimer__3ENsq {
  padding: 0 1rem;
  background-color: #f9fafb;
}

.Common_notes__M9Vd1 {
  padding: 1rem 1rem;
  background-color: #f9fafb;
}

.Common_divider__20hIP {
  height: 1px;
  border: 1px solid #dfe3e8;
}

.Common_markdownContainer__2Lgfx img {
  max-width: 100%;
}
.Common_markdownContainer__2Lgfx {
  color: var(--labelColor);
}

.Common_textImageBoxContainer__1vjql {
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  padding: 1rem;
  justify-content: space-between;
}
.Common_textImageBoxImage__2ncth {
  width: 40%;
}
.Common_textImageBoxImage__2ncth > img {
  width: 100%;
  height: auto;
}
.Common_textImageBoxText__1e8No {
  width: 55%;
}

.InfoDownloads_itemContainer__1U5xb {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 36rem;
}
.InfoDownloads_title__3izsc {
  bottom: 1rem;
}
.InfoDownloads_button__jlpeQ {
  display: flex;
  top: 1rem;
}

.PhoneNumber_container__2ptN3 {
  padding: 2rem;
}

.PhoneNumber_formContainer__EcS2v {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.SubmitJobDetails_row__1b34j {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.SubmitJobDetails_row__1b34j > :nth-child(2) {
  margin-left: 1rem;
}
.SubmitJobDetails_jobDetailsrow__18G-t {
  color: var(--labelColor);
  margin-bottom: 2rem;
}
.SubmitJobDetails_jobDetailsrow__18G-t > textarea {
  width: 100%;
  height: 10rem;
}
.SubmitJobDetails_textarea__2Wque::placeholder {
  font-family: Arial;
  font-weight: 400;
}

.SubmitJobDetails_checkboxRow__1V_8T {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  color: var(--labelColor);
}

.SubmitJobDetailsLayout_container__3SwVQ {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 3px;
  background-color: var(--hexWhite);
  width: 45rem;
  max-width: 100%;
}

.SubmitJobDetailsLayout_header__3_-rZ {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
}

.SubmitJobDetailsLayout_cross__3C2CR {
  cursor: pointer;
  justify-self: end;
  align-self: center;
}

.SubmitJobDetailsLayout_header__3_-rZ {
  color: var(--hexWhite);
}

.SubmitJobDetailsLayout_logo__2bDY_ {
  align-self: center;
  justify-self: center;
}

svg.SubmitJobDetailsLayout_cross__3C2CR path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.SubmitJobDetailsLayout_content__1qrGF {
  padding: 2rem;
}

.SubmitJobDetailsLayout_button__3LfJV {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.SubmitJobDetailsLayout_button__3LfJV > button {
  width: 8rem;
}

.SubmitJobDetailsLayout_button__3LfJV > button:nth-child(1) {
  margin-right: 1rem;
}

.Iframe_container__3QcOo {
  height: 100%;
  width: 100%;
  color: var(--labelColor);
}

.Iframe_iframe__uSFEY {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
@media only screen and (max-width: 580px) {
  .Iframe_iframe__uSFEY {
    min-height: 25rem;
  }
}

.Providers_container__2SMLz {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
}

.Providers_mainProviderContainer__2WNyx {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem;
  background-color: var(--inputGrey50percent);
}

.Providers_mainProviderContainer__2WNyx > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Providers_providerContainer__1q1QY {
  background-color: var(--inputGrey50percent);
  padding: 1rem;
}

.Providers_secondaryProviders__Yb8NX {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.Providers_secondaryProviders__Yb8NX > * {
  flex-grow: 1;
  max-width: 32%;
}

.Providers_providerContainer__1q1QY .Providers_image__xTqsT {
  position: relative;
  width: 100%;
}

.Providers_iframe__1Cyhj {
  height: 600px;
  margin-bottom: 2rem;
}

.LettingAndSales_container__3GdCZ {
  width: 100%;
}
.LettingAndSales_container__3GdCZ > * {
  height: 100%;
  width: 100%;
}

.LettingAndSales_iframe__3QP5u {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
.LettingAndSales_upgrade__2cIQ_ {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.LettingAndSales_title__1LLqh {
  margin-top: 2rem;
}
.LettingAndSales_noSponsorsSubtitle__2pMVZ {
  margin-top: 2rem;
}
.LettingAndSales_upgrade__2cIQ_ :nth-child(4) {
  cursor: pointer;
  margin-top: 2rem;
  height: auto;
  width: 25rem;
}
@media only screen and (max-width: 580px) {
  .LettingAndSales_container__3GdCZ > * {
    height: unset;
  }
}

.PackagePreview_container__UwhZS {
  display: flex;
  flex-direction: row;
}
.PackagePreview_container__UwhZS > p {
  margin-right: 0.5rem;
}

.PreLicenceList_container__1ygUx {
  display: flex;
  flex-direction: column;
}

.PreLicenceList_container__1ygUx > * {
  margin-top: 0.5rem;
}
.PreLicenceList_section__2_AXW {
  margin: 0.5rem 0 1rem 0;
}

.LicensingView_container__ieNL3 {
  display: flex;
  flex-direction: column;
}

.LicensingView_container__ieNL3 header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.LicensingView_container__ieNL3 header span {
  display: flex;
  flex-direction: row;
}

.LicensingView_container__ieNL3 header span > div {
  margin-right: 0.5rem;
}

.LicensingView_container__ieNL3 main {
  color: var(--labelColor);
}

.LicensingView_container__ieNL3 main > * {
  margin-bottom: 1rem;
}

.LicensingView_headerText__2L-Vv {
  color: var(--labelColor);
}

.Licensing_notes__ikLF9 {
  margin-top: 2rem;
}

.Licensing_heading__23ami {
  color: var(--primary);
}

.Licensing_alertBox__272Sm {
  margin-bottom: 2rem;
}
.Licensing_alertBox__272Sm > div {
  padding: 1.5rem;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.Licensing_alertBox__272Sm > div > svg {
  margin-left: 0.5rem;
  min-width: 2rem;
  min-height: 2rem;
}
.Licensing_alertBox__272Sm > div > span {
  font-size: 1rem;
  text-align: center;
}

.Licensing_noteText__3wNkb {
  color: var(--labelColor);
}

.Licensing_title__ZvBUm {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.Licensing_titleDesc__1Niyw {
  margin-left: 0.4rem;
}

.Licensing_crmUploads__3UHIF {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.Licensing_crmUploadContainer__TFTTx {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: rgba(240, 240, 240, 0.5);
  max-width: 20%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 1rem;
  margin: 0.32rem;
}

.Licensing_crmItemContainer__3_BSd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem;
  flex-wrap: nowrap;
}

.Licensing_crmItemLeft__1X1X7 {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  width: 10rem;
}

.Licensing_crmItemRight__223rv {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: flex-end;
  padding-left: 2rem;
}
@media screen and (max-width: 1550px) {
  .Licensing_crmUploadContainer__TFTTx {
    max-width: 48%;
    min-width: 48%;
  }
}
@media screen and (max-width: 1200px) {
  .Licensing_crmUploadContainer__TFTTx {
    max-width: 100%;
    min-width: 100%;
  }
}

.AlertSponsorBox_container__3UwIS {
  display: flex;
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
  border: 1px solid;
  border-color: var(--primary);
  align-items: center;
}

.PlanningView_container__3mNlw {
  display: flex;
  flex-direction: column;
}

.PlanningView_container__3mNlw header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.PlanningView_container__3mNlw header span {
  display: flex;
  flex-direction: row;
}

.PlanningView_container__3mNlw header span > div {
  margin-right: 0.5rem;
}

.PlanningView_container__3mNlw main {
  padding-left: 1rem;
  color: var(--labelColor);
}

.PlanningView_container__3mNlw main > * {
  margin-bottom: 1rem;
}
.PlanningView_planning__1YGqL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--grey4);
}
.PlanningView_planningWrap__2-XCy {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  height: 2.2rem;
}
.PlanningView_planningPrice__oa0Ci {
  background-color: var(--bg6);
  margin-right: 5rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
}

.Planning_container__2KlLa {
  margin-bottom: 1rem;
}

.Planning_layout__33tUt > *:not(:last-child) {
  margin-bottom: 1rem;
}

.Planning_container__2KlLa > *:not(:last-child) {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--bg2);
}
.Planning_upgrade__2YuvD {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.Planning_title__p7g9s {
  margin-top: 2rem;
}
.Planning_noSponsorsSubtitle__KxoKe {
  margin-top: 2rem;
}
.Planning_upgrade__2YuvD :nth-child(4) {
  cursor: pointer;
  margin-top: 2rem;
  height: auto;
  width: 25rem;
}

.Table_container__1f0Xy {
  width: 100%;
  background-color: var(--pureWhite);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  color: var(--labelColor);
}

.Table_container__1f0Xy tr {
  outline: solid 1px var(--grey4);
}

.Table_container__1f0Xy td,
th {
  vertical-align: middle;
  padding: 0.5rem;
}

.Table_container__1f0Xy thead > tr > * {
  padding-top: 1rem;
}

.Table_container__1f0Xy thead > tr > td:first-child {
  padding-left: 1rem;
}
.Table_container__1f0Xy thead > tr > td:last-child {
  padding-right: 1rem;
}

.Table_container__1f0Xy tbody > tr > td:first-child {
  padding-left: 1rem;
}

.Table_container__1f0Xy tbody > tr td:last-child {
  padding-right: 1rem;
}

.Table_noResultCell__1gbmj {
  display: table-cell;
}

.Table_noResult__Xdso4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GetSupport_container__12epN {
  width: 100%;
  display: grid;
  margin-bottom: 2rem;
  padding: 1rem 0;
}
.GetSupport_container__12epN > * {
  max-width: 1024px;
  width: 100%;
  justify-self: center;
}
.GetSupport_row__3a0qK {
  border-bottom: 1px solid var(--inputGrey50percent);
}

.GetSupport_name__6YxFW {
  color: var(--labelColor);
  padding-left: 0.8rem;
}

.GetSupport_main__3TTPm {
  display: grid;
  margin-top: 1rem;
  background-color: var(--hexWhite);
  grid-template-columns: 6fr 1fr 1fr 1fr;
}

.GetSupport_headerSection__16mhZ {
  background-color: var(--hexWhite);
  padding: 1.2rem;
  align-self: center;
  min-width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.GetSupport_section__1k6ZO {
  background-color: var(--listBackground);
  padding: 1.2rem;
  align-self: center;
  height: 100%;
  align-items: center;
  height: 4rem;
}

.GetSupport_document__hZjyn {
  background-color: var(--hexWhite);
  padding: 1.2rem;
  align-self: center;
  height: 100%;
  display: flex;
  align-items: center;
}
.GetSupport_vertical__1oHVS {
  background-color: var(--accent2);
}

.GetSupport_headerSection__16mhZ.GetSupport_vertical__1oHVS {
  color: var(--primary);
}

.GetSupport_verticalCenter__E3bIU {
  justify-content: center;
}

.GetSupport_actionContainer__3nBQ4 {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: var(--hexWhite);
  justify-content: space-between;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  color: var(--labelColor);
}

.GetSupport_buttonGroup__2sL9c {
  display: flex;
  grid-column-gap: 0.8rem;
  column-gap: 0.8rem;
}

.GetSupport_center__1M_8j {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  justify-items: center;
}

.GetSupport_bannerContainer__2X6iw {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--primary);
  color: var(--pureWhite);
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
  padding: 1rem;
}

.GetSupport_bannerContainer__2X6iw a {
  color: var(--black);
}

.Breadcrumb_container__1MY89 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--hexWhite);
  padding: 1rem;
}

.Breadcrumb_container__1MY89 > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--primary);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--bg2);
}

.Breadcrumb_container__1MY89 > header :first-child {
  margin-right: 1rem;
}

.Breadcrumb_homeIcon__MIxGP > path {
  stroke: var(--primary);
}

.Breadcrumb_container__1MY89 > header svg {
  height: 100%;
  width: auto;
}

.Breadcrumb_link__2Hqia {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: gray;
}
.Breadcrumb_selectedLink__1kkDW {
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--primary);
}
.Breadcrumb_circleLink__2vfXD {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  flex-direction: row;
}
.Breadcrumb_circleLink__2vfXD > svg {
  min-width: 22px;
}

.Breadcrumb_checked__1qseT > circle {
  fill: var(--primary);
}

.Breadcrumb_circleLink__2vfXD :nth-child(1) {
  margin-right: 0.5rem;
}
.Breadcrumb_circleLink__2vfXD :nth-child(2) {
  margin-right: 0.5rem;
}
.Breadcrumb_step__2vubl {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #919eab;
  padding: 0.4rem;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  flex-direction: column;
}
.Breadcrumb_innerStep__3aQg6 {
  display: flex;
  width: 300px;
  flex-direction: row;
  padding-left: 1rem;
}

.Breadcrumb_step__2vubl svg circle {
  stroke: var(--primary);
}

.Breadcrumb_step__2vubl .Breadcrumb_firstColumn__G_1QF {
  justify-self: center;
}

.Breadcrumb_navButton__2cy5e {
  display: none;
}

.Breadcrumb_unstuck__3hyer {
  display: none;
}
.Breadcrumb_stuck__3GSrt {
  display: none;
}

.Breadcrumb_infoTip__5Ux8R {
  display: none;
  position: relative;
  max-width: 15rem;
  transition: 1s;
}
.Breadcrumb_dropdown__3CqWk:hover .Breadcrumb_infoTip__5Ux8R {
  display: block;
  position: absolute;
  color: black;
  margin-left: 2rem;
  margin-top: -3rem;
  background-color: var(--pureWhite);
  border: solid 2px var(--grey4);
  padding: 0.5rem;
  z-index: 666;
  border-radius: 0.5rem;

  width: 15rem;
}

@media (max-width: 768px) {
  .Breadcrumb_container__1MY89 {
    display: none;
  }
}

.Journey_container__wGu5_ {
  display: grid;
  grid-template-columns: minmax(auto, 20rem) 1fr;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  max-width: 1530px;
  background-color: var(--backgroundColor);
}
.Journey_button__3uSAL {
  display: flex;
  justify-content: space-between;
  grid-column: 2;
}
@media only screen and (max-width: 768px) {
  .Journey_container__wGu5_ {
    grid-template-columns: unset;
  }
}
@media only screen and (max-width: 580px) {
  .Journey_container__wGu5_ {
    display: unset;
    padding: 0rem;
  }
}

.DraggableMarker_readOnly__1O4Tk {
  pointer-events: none;
  cursor: not-allowed;
}

.Map_container__Bv9KD {
  position: relative;
  height: 100%;
}
.Map_map__7VPI3 {
  height: 100%;
  padding: 0px;
}

.leaflet-container {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  min-height: 280px;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 500;
}

.PropertyInfo_container__edpAi {
  padding: 1.2rem 3rem;
  width: 100%;
  color: var(--labelColor);
}

.PropertyInfo_containerBottom__1Y-Sn {
  color: var(--labelColor);
  width: 100%;
  padding: 0 3rem;
}

.PropertyInfo_hDivider__ECwY_ {
  width: 100%;
  border: 1px solid #eee;
  height: 1px;
}

.PropertyInfo_disclaimer__gtzfv {
  text-transform: none;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.PropertyInfo_disclaimer__gtzfv > span {
  font-size: small;
}

.PropertyInfo_confirmButton__3GYhI {
  padding: 1rem;
  background-color: var(--primary);
  color: white;
  border-radius: 0.3rem;
  height: 3.2rem;
  width: 192px;
  display: flex;
  align-items: center;
  transition: width 0.3s;
  margin: 1rem;
  justify-content: flex-end;
}
.PropertyInfo_loading__38ZiH {
  width: 4rem;
  transition: width 1s;
}
.PropertyInfo_cPError__2vEH_ {
  color: rgba(255, 0, 0, 0.6);
}

.PropertyInfo_contactButton__3Oxc7 {
  background: var(--primary);
  padding: 1rem 3rem;
  margin: 0.4rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  text-align: center;
  border: none;
}
.PropertyInfo_contactButton__3Oxc7 :hover {
  cursor: pointer;
}
.PropertyInfo_contactButton__3Oxc7 > a {
  text-decoration: none;
  color: #fff;
}

.PropertyInfo_topMenu__1gXLY {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: var(--hexWhite);
  padding: 1rem;
  border-radius: 5px;
  border-bottom: 3px solid;
  border-color: var(--grey4);
}
.PropertyInfo_topMenu__1gXLY :hover {
  text-decoration: underline;
}

.PropertyInfo_topMenu__1gXLY > span > a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.PropertyInfo_title__fDDCJ {
  color: var(--primary);
  margin-bottom: 1rem;
}

.PropertyInfo_containerLeftRight__3jxnA {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  padding-top: 1.2rem;
  padding-bottom: 2rem;
}
.PropertyInfo_rentalAndSalesContainer__150O0 {
  background-color: var(--hexWhite);
  padding: 2rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}

.PropertyInfo_rentalAndSalesContainer__150O0 > * {
  margin-bottom: 1rem;
}

.PropertyInfo_rentalAndSalesContainer__150O0 > span {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.PropertyInfo_left__3Rf7F {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.PropertyInfo_map__1UODS {
  z-index: 0;
  height: 100%;
}
.PropertyInfo_mapWarning__2XwKk {
  background-color: rgb(255, 184, 184);
  padding: 0rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  text-align: center;
}
.PropertyInfo_mapGuide__2NGRB {
  background-color: white;
  padding: 0.4rem;
  padding-left: 1rem;
  display: flex;
  justify-content: left;
  cursor: pointer;
  align-items: center;
  margin-bottom: 2rem;
}
.PropertyInfo_mapGuide__2NGRB > svg {
  margin-left: 0.5rem;
  max-width: 16px;
  fill: var(--labelColor);
}
.PropertyInfo_mapGuide__2NGRB:hover {
  text-decoration: underline;
}
.PropertyInfo_mapGuideButton__30vhG {
  padding: 0.4rem;
  display: flex;
  justify-content: right;
  cursor: pointer;
  align-items: center;
  padding-top: 2.5rem;
}
.PropertyInfo_mapGuideButton__30vhG > button {
  margin-bottom: 0.5rem;
  align-self: flex-end;
}
.PropertyInfo_right__3SAtR {
  width: 100%;
}
.PropertyInfo_formContainer__1L9XI {
  margin-bottom: 2rem;
}
.PropertyInfo_formTop__2UKes {
  color: var(--primary);
  background-color: var(--hexWhite);
  padding: 2rem 3.5rem 2rem 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PropertyInfo_formTop__2UKes > svg > path {
  stroke: var(--primary);
}
.PropertyInfo_formTop__2UKes:hover {
  text-decoration: underline;
}
.PropertyInfo_journeyButton__F1hB9 {
  display: flex;
  justify-content: flex-end;
}
.PropertyInfo_notClickable__2P7K5 {
  pointer-events: none;
  cursor: not-allowed;
}
.PropertyInfo_continueButtonContainer__n00q6 {
  padding: 0.8rem 0rem;
  display: flex;
  justify-content: flex-end;
}

.PropertyInfo_tableContainer__3NVo- {
  background: white;
  padding: 0 2rem;
}

@media only screen and (max-width: 768px) {
  .PropertyInfo_container__edpAi {
    padding: unset;
  }
  .PropertyInfo_mapGuide__2NGRB {
    padding: 0rem 2rem;
  }
  .PropertyInfo_rentalAndSalesContainer__150O0 {
    padding: 1rem;
  }
  .PropertyInfo_containerLeftRight__3jxnA {
    display: unset;
  }
  .PropertyInfo_continueButtonContainer__n00q6 {
    padding: 0.8rem 0rem;
    display: flex;
    justify-content: flex-end;
  }
}

.CompareRents_container__2SPAV {
}

.CompareRents_title__d5Za7 {
  color: var(--primary);
  margin-bottom: 1rem;
}

.CompareRents_hDivider__RNhUZ {
  width: 100%;
  border: 1px solid #eee;
  height: 1px;
}

.CompareRents_disclaimer__2MOmy {
  text-transform: none;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.CompareRents_disclaimer__2MOmy > span {
  font-size: small;
}

.CompareRents_contactButton__uUIei {
  background: var(--primaryRGB80);
  padding: 1rem 3rem;
  margin: 0.4rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  text-align: center;
  border: none;
}
.CompareRents_contactButton__uUIei > a {
  text-decoration: none;
  color: #fff;
}

.ValuationTable_container__25diV {
  width: 100%;
  background-color: var(--pureWhite);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  color: var(--labelColor);
}

.ValuationTable_container__25diV tr {
  outline: solid 1px var(--grey4);
}

.ValuationTable_container__25diV td,
th {
  vertical-align: middle;
  padding: 0.5rem;
}

.ValuationTable_container__25diV thead > tr > * {
  padding-top: 1rem;
}

.ValuationTable_container__25diV thead > tr > td:first-child {
  padding-left: 1rem;
}
.ValuationTable_container__25diV thead > tr > td:last-child {
  padding-right: 1rem;
}

.ValuationTable_container__25diV tbody > tr > td:first-child {
  padding-left: 1rem;
}

.ValuationTable_container__25diV tbody > tr td:last-child {
  padding-right: 1rem;
}

.ValuationTable_noResultCell__1dzb8 {
  display: table-cell;
}

.ValuationTable_noResult__Mu5m6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ValuationTable_header__2_uoR {
text-align: left;} 

.ValuationTable_noResult__Mu5m6 > svg {
width: 5rem;
height: 5rem
}
.MapModalGuide_container__3fX_Q {
  padding: 1rem;
  color: var(--labelColor);
  height: 100%;
  max-height: 90vh;
}
.MapModalGuide_closeIcon__2u7Il {
  display: flex;
  justify-content: flex-end;
}
.MapModalGuide_WrapSteps__-NOet {
  padding-bottom: 1rem;
}
.MapModalGuide_steps__2MAC_ {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MapModalGuide_steps__2MAC_ > p {
  max-width: 25rem;
}

.MapModalGuide_steps__2MAC_ > img {
  max-width: 25rem;
}

@media (max-width: 768px) {
  .MapModalGuide_steps__2MAC_ > p {
    max-width: 25rem;
  }
  .MapModalGuide_steps__2MAC_ > img {
    max-width: 25rem;
    width: 100%;
  }
}

.SendEmailModal_container__2vn6h {
  color: var(--labelColor);
  height: 100%;
  max-height: 90vh;
  width: 80vw;
  max-width: 1000px;
}
.SendEmailModal_closeIcon__2aRQw {
  display: flex;
  justify-content: flex-end;
  background: var(--primary);
}
.SendEmailModal_closeIcon__2aRQw > svg {
  filter: invert(66%) sepia(68%) saturate(1721%) hue-rotate(20deg)
    brightness(150%) contrast(103%);
  margin: 1rem;
  cursor: pointer;
}

.SendEmailModal_title__2hszp {
  padding: 1rem 0rem;
  background: var(--primary);
  color: white;
  text-align: center;
}

.SendEmailModal_formContainer__22ozX {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SendEmailModal_formRow__3LmGb {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  padding: 1rem 0rem;
}
.SendEmailModal_formRow__3LmGb > label {
  width: 45%;
}

.SendEmailModal_horBar__3hJZe {
  height: 1px;
  border: 1px solid rgba(200, 200, 200, 0.4);
  width: 90%;
  margin-top: 1.5rem;
}

.SendEmailModal_subtitle__2aNei {
  margin: 1rem 0rem;
  width: 90%;
  text-align: left;
}

.SendEmailModal_messageBox__120dX {
  width: 90%;
  height: -webkit-max-content;
  height: max-content;
  font-family: "Basier Circle";
}

.SendEmailModal_bottomButtons__2Fc3- {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: flex-end;
  margin: 2rem 0rem;
}

.SendEmailModal_bottomButtons__2Fc3- > button {
  width: 8rem;
}

.StatusModal_container__2ZxSs {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 0rem 2.5rem 3rem 2.5rem;
  align-items: center;
  position: relative;
  max-width: 40rem;
  width: 100%;
  --magic-number: 0.09719;
  --skew-padding: calc(-100vw * var(--magic-number));
  min-width: 35rem;
}

.StatusModal_container__2ZxSs > *:not(.StatusModal_cross__2-sih) {
  z-index: 1;
}
.StatusModal_cross__2-sih {
  z-index: 99;
  align-self: flex-end;
  margin-top: 2.5rem;
  cursor: pointer;
}
.StatusModal_backgroundWave__2JnP_ {
  position: absolute;
  background-size: 100% auto;
}

.StatusModal_container__2ZxSs img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 3rem;
}

.StatusModal_subtitle__2Cqib {
  color: var(--primary);
  text-align: center;
}

.StatusModal_noSponsorsSubtitle__2W2ek svg:hover {
  cursor: pointer;
}
.StatusModal_logo__2EzGE {
  margin-top: 5rem;
  display: flex;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  flex-direction: column;
  align-items: center;
}

.StatusModal_actions__-cupH {
  width: 100%;
}

.StatusModal_box__137cg {
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
  box-shadow: 1px 5px var(--grey4);
  border-top: 5px solid var(--primary);
}
.StatusModal_boxMain__pwm_j {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
}
.StatusModal_boxMain__pwm_j > svg[id="arrow-up"] {
  filter: saturate(0%) brightness(70%);
  transform: rotate(180deg);
}
.StatusModal_boxMain__pwm_j > svg[id="arrow"] {
  filter: saturate(0%) brightness(70%);
}
.StatusModal_actionLogo__3ioPo {
  margin-right: 0.5rem;
}

.StatusModal_licenceContainer__1PF8F {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.StatusModal_licence__2my07 {
  padding-left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.StatusModal_docContainer__sk1FG {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.StatusModal_docDivider__13uWT {
  width: 105%;
  border-bottom: solid 1px #ccc;
}
.StatusModal_document__4mnj1 {
  padding-left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.StatusModal_address__33Rmr {
  color: #777;
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}
.StatusModal_address__33Rmr > svg {
  filter: brightness(87%) saturate(0%);
  margin-right: 0.5rem;
}

.StatusModal_actionRequiredTitle__1O_DQ {
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.StatusModal_actionRequiredEmpty__iFRDm {
  color: #637381;
  display: flex;
  align-items: center;
}
.StatusModal_actionRequiredGrey__1GiAQ {
  color: #637381;
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.StatusModal_actionRequiredYellow__3a26K {
  color: #f2c94c;
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.StatusModal_actionRequiredRed__3vuJ3 {
  color: #e2574c;
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.StatusModal_actionRequiredGreen__3eQO- {
  color: #4eaea5;
  display: flex;
  align-items: center;
}
.StatusModal_expires__j8CS- {
  color: #637381;
  width: 50%;
  display: contents;
  font-size: 11px;
}
.StatusModal_container__2ZxSs footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}
.StatusModal_container__2ZxSs footer > * {
  min-width: 15rem;
}
@media only screen and (max-width: 768px) {
  .StatusModal_container__2ZxSs {
    padding: 0 1rem;
    min-width: unset;
  }
  .StatusModal_container__2ZxSs footer > * {
    min-width: 12rem;
    margin-bottom: 2rem;
  }
}

.IframeHeader_header__3mCXU {
  width: 100%;
  height: 72px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: var(--hexaWhite);
}
.IframeHeader_logo__1rJ9m {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.IframeHeader_logo__1rJ9m > img {
  max-width: 8rem;
  height: 44px;
  width: auto;
}
.IframeHeader_navbar__SQDF2 {
  color: var(--labelColor);
  display: flex;
  margin-bottom: 1rem;
  height: 100%;
  align-items: center;
  padding-right: 1rem;
}
.IframeHeader_links__peElq {
  display: flex;
  text-decoration: none;
  align-items: flex-start;
}
.IframeHeader_links__peElq > img {
  padding: 0 0.2rem;
}

@media (max-width: 768px) {
  .IframeHeader_header__3mCXU {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .IframeHeader_logo__1rJ9m {
    padding: 14px;
  }
}

.SavedJobDetails_container__1Xfyy {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 3px;
  background-color: var(--hexWhite);
  width: 25rem;
  max-width: 100%;
}

.SavedJobDetails_header__11u5V {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
  color: var(--hexWhite);
}

.SavedJobDetails_cross__3yQ8T {
  cursor: pointer;
  justify-self: end;
  align-self: center;
}

.SavedJobDetails_logo__2aTJt {
  align-self: center;
  justify-self: center;
}

svg.SavedJobDetails_cross__3yQ8T path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.SavedJobDetails_content__2bjKM {
  padding: 1rem 2rem 2rem 2rem;
}
.SavedJobDetails_mailLogo__37_Av {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.SavedJobDetails_mainText__16XSK {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}
.SavedJobDetails_subText__2jy_P {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: var(--labelColor);
}
.SavedJobDetails_button__39mFX {
  display: flex;
  justify-content: center;
}

.UpgradeBanner_container__1sPOK {
  background-color: var(--lightBlue);
  position: relative;
  padding: 2rem;
  color: var(--labelColor);
}
.UpgradeBanner_container__1sPOK > svg {
  position: absolute;
  right: 0;
  z-index: -1;
  top: 0;
}
.UpgradeBanner_listContainer__2_kw1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.UpgradeBanner_priceLogoWrap__1aRHQ {
  display: flex;
  right: 0;
  position: absolute;
  max-width: 14rem;
  top: 0;
}
.UpgradeBanner_priceLogoWrap__1aRHQ :nth-child(1) {
  display: flex;
  margin: 2rem;
}

.UpgradeBanner_header__1c9kK {
  color: var(--primary);
}

.UpgradeBanner_header__1c9kK :nth-child(1) {
  margin-bottom: 1rem;
}
.UpgradeBanner_header__1c9kK :nth-child(2) {
  margin-bottom: 1rem;
  color: var(--labelColor);
}
.UpgradeBanner_list__35f12 {
  margin: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
}
.UpgradeBanner_list__35f12 > span {
  margin-bottom: 0.5rem;
}
.UpgradeBanner_button__1yoy_ {
  cursor: pointer;
}
.UpgradeBanner_wrapLogos__1dXm9 {
  padding: 2rem 2rem 1rem 2rem;
}
.UpgradeBanner_logos__1wYoM {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.UpgradeBanner_logos__1wYoM > img {
  max-height: 5rem;
  height: auto;
  max-width: 10rem;
  margin-right: 2rem;
}
.UpgradeBanner_logos__1wYoM > svg {
  max-height: 5rem;
  height: auto;
  max-width: 10rem;
  margin-right: 2rem;
}

.Layout_container__1RDBR {
  background: var(--backgroundColor);
  width: 100%;
  display: grid;
  grid-template-rows: 72px auto;
  height: 100%;
}
.Layout_mainContainer__112YW {
  display: flex;
  justify-content: center;
  background: var(--backgroundColor);
  height: 100%;
}

@media (max-width: 768px) {
  .Layout_header__14cTt {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.Property_main__Yn71Y {
  width: 100%;
  height: auto;
  display: grid;
  justify-items: center;
  padding: 0 0rem 1.875rem 0rem;
}
iframe[style*="2147483647"] {
  display: none;
}
.Property_actionsRequired__1WT6h {
  width: 100%;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcf1cdb2;
  padding: 0 1rem;
}
.Property_actionsRequiredCritical__1cskc {
  width: 100%;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbe6e4;
  padding: 0 1rem;
}
.Property_warningText__1bckO {
  margin-left: 1rem;
}
.Property_leftSide__2kZHa {
  display: flex;
  align-items: center;
  color: var(--labelColor);
}
.Property_rightSide__2QfpQ {
  display: flex;
  align-items: center;
}
.Property_rightSide__2QfpQ > button {
  color: var(--labelColor);
  border-color: var(--labelColor);
}
.Property_footer__246Nu {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0.312rem 1.5rem 0 0;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .Property_actionsRequired__1WT6h {
    height: unset;
    flex-direction: column;
    padding: 0.5rem 1rem;
  }
  .Property_actionsRequiredCritical__1cskc {
    height: unset;
    flex-direction: column;
    padding: 0.5rem 1rem;
  }
  .Property_leftSide__2kZHa {
    margin-bottom: 0.5rem;
  }

  .Property_footer__246Nu {
    display: none;
    padding-bottom: 5rem;
    margin: 1.875rem 0 0 1.5rem;
    justify-content: flex-start;
  }
}

.SponsorContainer_container__2UIOD {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.SponsorContainer_container__2UIOD img {
  max-height: 2rem;
  width: auto;
}

.TabView_container__3-aUn {
  width: 100%;
  display: flex;
  padding: 0.8rem 0;
  text-align: center;
  background-color: var(--hexWhite);
  flex-wrap: wrap;
  min-height: 3.58rem;
  justify-content: center;
  overflow-x: hidden;
}
.TabView_wrap__31tT3 {
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.TabView_stepWrap__A6sUQ {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.TabView_link__3iKpl {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey5);
}
.TabView_selected__9Xraq {
  color: var(--primaryRGB);
}

.TabView_selected__9Xraq > svg > path {
  stroke: var(--primary);
}
.TabView_selected__9Xraq > svg path[class="propertyIcon"] {
  stroke: var(--hamptonsSecondary);
}
.TabView_selected__9Xraq > svg path[class="homeIcon"] {
  stroke: var(--hamptonsSecondary);
}
.TabView_selected__9Xraq > svg path[class="planIcon"] {
  stroke: var(--hamptonsSecondary);
}

.TabView_link__3iKpl > span {
  padding: 0 1rem;
}
.TabView_steps__B81fj {
  display: flex;
  justify-content: center;
  width: 80%;
}
.TabView_sponsoredBy__2fUJB {
  width: 20%;
}
.TabView_upgradeButton__2qdJ- {
  margin-right: 2rem;
  min-width: 12rem;
}
.TabView_upgradeButton__2qdJ-:hover {
  cursor: pointer;
}

.TabView_progressBar__1sapz {
  border-bottom: solid 4px var(--secondary);
}
.TabView_progressBarSizeSmall__1HJZ_ {
  width: 35%;
}
.TabView_progressBarSizeMedium__2rxfi {
  min-width: 36rem;
  width: 45%;
}
.TabView_progressBarSizeLarge__2hweZ {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .TabView_progressBar__1sapz {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .TabView_container__3-aUn {
    display: none;
    min-height: 6rem;
    justify-content: center;
    align-content: center;
    padding: unset;
  }
  .TabView_stepWrap__A6sUQ {
    flex-direction: column;
    align-items: center;
  }
  .TabView_upgradeButton__2qdJ- {
    margin-right: unset;
  }
  .TabView_link__3iKpl > span {
    display: none;
  }
  .TabView_step__3TRLr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
  }
  .TabView_steps__B81fj {
    min-width: 60vw;
    margin-bottom: 0.5rem;
    width: unset;
  }
  .TabView_sponsoredBy__2fUJB {
    width: unset;
    display: none;
  }
  .TabView_progressBar__1sapz {
    display: none;
  }
}

.noUprnMapModal_container__KpHaO {
  max-width: 40rem;
  padding: 2rem;
}
.noUprnMapModal_CrossIcon__11UG8 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  cursor: pointer;
}
.noUprnMapModal_title__2OEkE {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.noUprnMapModal_subTitle__iQlCm {
  margin-bottom: 2rem;
}
.noUprnMapModal_description__2jMQ3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.noUprnMapModal_button__1k8H9 {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.noUprnMapModal_start__3jChO {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.Divider_container__35rPE {
  display: flex;
  align-items: center;
}

.Divider_container__35rPE > :nth-child(2) {
  margin: 0 0.5rem;
  color: var(--grey5);
}

.Divider_container__35rPE span {
  width: 100%;
  height: 1px;
  background-color: var(--grey5);
}

.List_container__3mVXr {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.List_list__2qFG2 {
  height: 9rem;
  background: var(--hexWhite);
  padding: 12px 0 12px 16px;
  color: var(--labelColor);
  margin-top: 0;
  border: none;
  border-radius: 4;
  list-style-type: none;
  position: relative;
  text-transform: none;
  display: flex;
  flex-direction: column;
  height: 130;
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 1px rgb(9 30 66 / 31%), 0px 8px 16px rgb(9 30 66 / 25%);
}
@media (max-width: 460px) {
  .List_list__2qFG2 {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.List_list__2qFG2:disabled {
  background: var(--cmyk);
}

.List_listItem__1rtqZ {
  padding: 0;
  cursor: pointer;
  padding-left: 0.5rem;
  color: var(--black);
}

.List_listItem__1rtqZ:hover {
  color: var(--hexWhite);
  background-color: var(--primary);
}

.List_selectedListItem__2JVhR {
  color: var(--hexWhite);
  background-color: var(--primary);
  font-weight: 700;
}

.List_errorText__2iFJ3 {
  color: var(--red);
  padding-top: 6px;
  width: 436px;
}

.List_downArrow__3h3Cn {
  pointer-events: none;
  position: absolute;
  left: calc(100% - 10%);
  top: 46px;
}
.List_downArrow__3h3Cn > path {
  stroke: var(--black);
}

.Address_container__1EWJZ {
  display: flex;
  flex-direction: column;
}

.Address_container__1EWJZ > * {
  margin-top: 1rem;
}

.Address_row__3SdUC {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  align-items: flex-end;
}
.Address_NewSearch__317kz {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2rem;
}

.Address_row__3SdUC > :nth-child(1) {
  margin-right: 1rem;
}
.Address_noUPRN__2Lq6j {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.Address_noUPRN__2Lq6j > button {
  max-width: unset;
  width: 100%;
}
@media (max-width: 768px) {
  .Address_container__1EWJZ {
    margin-top: 2rem;
  }
}

