.container {
  display: inline-block;
  border: 2px solid var(--primary);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0;
  text-decoration: none;
  background: var(--pureWhite);
  color: var(--primary);
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
}

.container:hover {
  border: 2px solid var(--primary);
  background: var(--primary);
  color: var(--pureWhite);
}

.delete {
  border: 2px solid var(--error);
  color: var(--error);
  width: 100%;
}

.delete:hover {
  border: 2px solid var(--error);
  background: var(--error);
  color: var(--pureWhite);
}
