.message {
  display: flex;
  justify-content: center;
  background-color: var(--warningColor);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.9rem 1.1rem;
  line-height: 1.1rem;
}
.message svg {
  margin-right: 1.1rem;
}
