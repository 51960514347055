.container {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  background: none;
  font-weight: 600;
  font-size: 1rem;
  font-style: normal;
  text-align: center;
  font-family: "Basier Circle";
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.textColour {
  color: var(--primary);
}

.loader {
  position: relative;
  width: 0px;
  height: 0px;
  top: -15px;
  left: 17px;
}

.noIcon {
  justify-content: center;
}

.large {
  min-height: 3.2rem;
}

.small {
  min-height: 2rem;
}

.primary {
  background-color: var(--primary);
  color: var(--pureWhite);
  max-width: 18rem;
  width: unset;
}

.secondary {
  background-color: var(--pureWhite);
  color: var(--primary);
  border: 2px solid var(--primary);
}

.fullWidth {
  background-color: var(--primary);
  color: var(--pureWhite);
  width: 100%;
  margin-right: unset;
}

.destructive {
  background-color: var(--errorRed);
  color: var(--pureWhite);
}
.greyed {
  background-color: var(--hamptonsNewDisabled);
  color: var(--pureWhite);
}

.outlines {
  background-color: var(--pureWhite);
  border: 2px solid rgba(196, 196, 196, 0.4);
}

.discreet {
  background-color: var(--primaryRGB20);
  color: var(--primary);
}
.discreet > span {
  display: flex;
  justify-content: center;
}

.noBorder {
  border: none;
}

.padded {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: var(--primary);
  color: var(--pureWhite);
  max-width: 18rem;
  width: 18rem;
}

button.container:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.verifyAddress {
  margin-left: 2rem;
  border: 2px solid var(--black);
  color: var(--black);
  font-weight: 700 !important;
  height: 1rem;
}
.bannerButton {
  margin-left: 2rem;
  border: 2px solid var(--black);
  color: var(--black);
  font-weight: 700 !important;
  height: 1rem;
  white-space: nowrap;
  width: fit-content;
}
.verifyAddress > span {
  font-weight: 600 !important;
}
