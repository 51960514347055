.container {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.container a {
  color: #1f1f1f;
  border: 1px solid var(--greyPrimary);
  padding: 0.8rem 1rem;
  margin: 0 0.1rem;
  cursor: pointer;
  outline: none;
  background-color: var(--pureWhite);
  font-style: normal;
  font-weight: normal;
  line-height: 1.2rem;
  text-decoration: none;
}

.container a.active {
  color: var(--pureWhite);
  background-color: var(--primary);
  border: 1px solid var(--grey4);
  box-shadow: inset 0px -3px 0px var(--alertBox);
}
