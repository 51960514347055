.container {
  display: flex;
  flex-direction: row;
}

.container > * {
  margin-right: 1rem;
}
.container > :last-child {
  margin-right: 0;
}
@media only screen and (max-width: 480px) {
  .container > * {
    margin-right: 0.5rem;
  }
}
