.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.icon {
  cursor: pointer;
  height: 100%;
  width: auto;
}

.icon path {
  stroke: var(--primary);
}

.container .toolTipText {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
  margin-left: -4.5rem;
}
.container:hover .toolTipText {
  visibility: visible;
}
