.container {
  background-color: var(--backgroundColorSecondary);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
}

.container main {
  margin-top: 1rem;
}

.container header {
  display: flex;
  justify-content: space-between;
}
.topModal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.crossIcon {
  cursor: pointer;
}
.modalSnippet {
  color: var(--labelColor);
}
.certification {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.certification :nth-child(1) {
  margin-right: 0.2rem;
}
.licenseName {
  max-width: 30rem;
}
.licensePrice {
  min-width: 6rem;
}
.actionGroup {
  display: flex;
}

.actionGroup > * {
  cursor: pointer;
  margin-left: 0.6rem;
}
.toolTipInfo {
  display: flex;
  align-items: center;
}

.toolTipInfo .toolTipInfoText {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 6rem;
  height: 1.8rem;
  margin-top: 4rem;
  margin-left: -2.5rem;
}
.toolTipInfo:hover .toolTipInfoText {
  visibility: visible;
}
.toolTip {
  display: flex;
  align-items: center;
}

.toolTip .toolTipText {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary);
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 1rem 0px;
  display: flex;
  color: var(--hexWhite);
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
  margin-left: -4.5rem;
}
.toolTip:hover .toolTipText {
  visibility: visible;
}
.addIcon {
  display: flex;
  align-items: center;
}

.uploadSection {
  background-color: var(--hexWhite);
  padding: 1rem;
  margin-top: 0.5rem;
}

.uploadSection main {
  padding-left: 0.5rem;
}
.expand {
  cursor: pointer;
}
.documentInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 1rem;
}

.documentInfo > :nth-child(1) {
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 0.3rem;
  text-decoration: none;
  color: inherit;
}
.documentInfo > :nth-child(2) {
  justify-self: end;
}
.actionGroup {
  color: var(--primary);
  max-height: 1.6rem;
}
.checkbox {
  margin-top: 0.5rem;
  display: flex;
}
.checkbox > :nth-child(1) {
  width: unset;
  margin-right: 0.5rem;
}
.referenceDoc {
  display: flex;
  align-items: center;
}

.editDoc {
  cursor: pointer;
}
.expiryDate {
  cursor: unset;
  color: var(--black);
}

.markdownContainer {
  padding: 1rem 1.5rem;
}
