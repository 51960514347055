.container input {
  width: 100%;
  border: 2px solid var(--inputGrey);
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
}

.container input:hover {
  border: 2px solid var(--activeInput);
}
.container input:focus-within {
  border: 2px solid var(--activeInput);
}

.default {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.container input[class="PhoneInputInput"] {
  padding: 1.5rem 1rem 1.5rem 0.5rem;
}

.dashboardFilter {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.inputError input {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.inputError input:hover {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.inputError input:focus-within {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}

.inputContainer {
  display: flex;
  width: 100%;
}

.countryCodeSelect {
  width: 6rem;
  border: 2px solid var(--inputGrey);
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0.9rem;
  font-style: normal;
  padding: 0.2rem;
  margin-right: 0.2rem;
}
.countryCodeSelect:hover {
  border: 2px solid var(--activeInput);
}
.countryCodeSelect:focus-within {
  border: 2px solid var(--activeInput);
}
.inputError select {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.inputError select:hover {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
.inputError select:focus-within {
  border: 2px solid var(--errorRed);
  background-color: var(--backgroundError);
}
