.container {
  width: 100%;
  display: grid;
  margin-bottom: 2rem;
  padding: 1rem 0;
}
.container > * {
  max-width: 1024px;
  width: 100%;
  justify-self: center;
}
.row {
  border-bottom: 1px solid var(--inputGrey50percent);
}

.name {
  color: var(--labelColor);
  padding-left: 0.8rem;
}

.main {
  display: grid;
  margin-top: 1rem;
  background-color: var(--hexWhite);
  grid-template-columns: 6fr 1fr 1fr 1fr;
}

.headerSection {
  background-color: var(--hexWhite);
  padding: 1.2rem;
  align-self: center;
  min-width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.section {
  background-color: var(--listBackground);
  padding: 1.2rem;
  align-self: center;
  height: 100%;
  align-items: center;
  height: 4rem;
}

.document {
  background-color: var(--hexWhite);
  padding: 1.2rem;
  align-self: center;
  height: 100%;
  display: flex;
  align-items: center;
}
.vertical {
  background-color: var(--accent2);
}

.headerSection.vertical {
  color: var(--primary);
}

.verticalCenter {
  justify-content: center;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: var(--hexWhite);
  justify-content: space-between;
  column-gap: 1rem;
  color: var(--labelColor);
}

.buttonGroup {
  display: flex;
  column-gap: 0.8rem;
}

.center {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  justify-items: center;
}

.bannerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--primary);
  color: var(--pureWhite);
  column-gap: 0.5rem;
  padding: 1rem;
}

.bannerContainer a {
  color: var(--black);
}
