.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  max-width: 36rem;
}

.poweredBy {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 36rem;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .poweredBy {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}
