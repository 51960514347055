.react-select {
  width: 100%;
}

.react-select__control {
  border: 2px solid var(--inputGrey) !important;
  height: 3.3rem;
  padding: 0.5rem 0;
  border-radius: 4px;
}

.react-select__control:hover {
  border-color: var(--activeInput) !important;
}
.react-select__control:focus-within {
  border-color: var(--activeInput) !important;
  box-shadow: none;
}

.react-select__menu {
  margin-top: 2px !important;
  z-index: 6 !important;
}

.react-select__option {
  color: var(--balck);
}

.react-select__option:hover {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
  cursor: pointer;
}

.react-select__option--is-selected {
  background-color: var(--primaryRGB5percent) !important;
  color: var(--black) !important;
}
.react-select__option--is-focused {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
}

.secondary {
  width: 100%;
}

.secondary__control {
  border: none !important;
  height: 3.3rem;
  padding: 0.5rem 0;
  border-radius: 4px;
  color: var(--primary) !important;
}

.secondary__control:hover {
  border-color: var(--activeInput) !important;
}
.secondary__control:focus-within {
  border-color: var(--activeInput) !important;
  box-shadow: none;
}

.secondary__menu {
  margin-top: 2px !important;
  z-index: 6 !important;
}
.secondary__placeholder {
  color: var(--primary) !important;
}
.secondary__indicator > svg {
  fill: var(--primary) !important;
}
.secondary__indicator-separator {
  display: none;
}

.secondary__option {
  color: var(--black);
}

.secondary__option:hover {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
  cursor: pointer;
}

.secondary__option--is-selected {
  background-color: var(--primaryRGB5percent) !important;
  color: var(--black) !important;
}
.secondary__option--is-focused {
  background-color: var(--primary) !important;
  color: var(--hexWhite) !important;
}
