.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default {
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
}

.light {
  background-color: white;
}
