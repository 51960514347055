.container {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 3px;
  background-color: var(--hexWhite);
  width: 45rem;
  max-width: 100%;
}

.header {
  background-color: var(--hamptonsNewPrimary);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem;
}

.cross {
  cursor: pointer;
  justify-self: end;
  align-self: center;
}

.header {
  color: var(--hexWhite);
}

.logo {
  align-self: center;
  justify-self: center;
}

svg.cross path {
  stroke: var(--hexWhite);
  fill: var(--hexWhite);
}

.content {
  padding: 2rem;
}

.button {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button > button {
  width: 8rem;
}

.button > button:nth-child(1) {
  margin-right: 1rem;
}
