.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0rem 2.5rem 3rem 2.5rem;
  align-items: center;
  position: relative;
  max-width: 40rem;
  width: 100%;
  --magic-number: 0.09719;
  --skew-padding: calc(-100vw * var(--magic-number));
  min-width: 35rem;
}

.container > *:not(.cross) {
  z-index: 1;
}
.cross {
  z-index: 99;
  align-self: flex-end;
  margin-top: 2.5rem;
  cursor: pointer;
}
.backgroundWave {
  position: absolute;
  background-size: 100% auto;
}

.container img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 3rem;
}

.subtitle {
  color: var(--primary);
  text-align: center;
}

.noSponsorsSubtitle svg:hover {
  cursor: pointer;
}
.logo {
  margin-top: 5rem;
  display: flex;
  row-gap: 0.8rem;
  flex-direction: column;
  align-items: center;
}

.actions {
  width: 100%;
}

.box {
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
  box-shadow: 1px 5px var(--grey4);
  border-top: 5px solid var(--primary);
}
.boxMain {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
}
.boxMain > svg[id="arrow-up"] {
  filter: saturate(0%) brightness(70%);
  transform: rotate(180deg);
}
.boxMain > svg[id="arrow"] {
  filter: saturate(0%) brightness(70%);
}
.actionLogo {
  margin-right: 0.5rem;
}

.licenceContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.licence {
  padding-left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.docContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.docDivider {
  width: 105%;
  border-bottom: solid 1px #ccc;
}
.document {
  padding-left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.address {
  color: #777;
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}
.address > svg {
  filter: brightness(87%) saturate(0%);
  margin-right: 0.5rem;
}

.actionRequiredTitle {
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.actionRequiredEmpty {
  color: #637381;
  display: flex;
  align-items: center;
}
.actionRequiredGrey {
  color: #637381;
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.actionRequiredYellow {
  color: #f2c94c;
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.actionRequiredRed {
  color: #e2574c;
  display: flex;
  align-items: center;
  width: 70%;
  padding: 0.5rem 0;
}
.actionRequiredGreen {
  color: #4eaea5;
  display: flex;
  align-items: center;
}
.expires {
  color: #637381;
  width: 50%;
  display: contents;
  font-size: 11px;
}
.container footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}
.container footer > * {
  min-width: 15rem;
}
@media only screen and (max-width: 768px) {
  .container {
    padding: 0 1rem;
    min-width: unset;
  }
  .container footer > * {
    min-width: 12rem;
    margin-bottom: 2rem;
  }
}
